import React, { useState, useRef, useEffect, useMemo } from "react"
import DraggableContainer from "../DraggableContainer"
import tw, { styled, css } from "twin.macro"
import { ArrowsMove } from "@styled-icons/bootstrap"
import { CNames, ENames, ElementsData } from "../../data/elements"
import { SimpleToggle } from "../CommonInputs"
import { useSnapshot } from "valtio"
import {ExplodeName} from '../../utils/misc'
import state, {stateActions} from "../Store"
const ArrowMoveColored = styled(ArrowsMove)`
color: white;
`
const Control = ({ title, itemList, checkedStates, onCheckedstate}) => {
  return (
    <div
      tw="relative
    m-5 p-5
    rounded-md
    cursor-auto
    filter drop-shadow-lg"
    css={css`background-color: rgba(34,34,34,0.45)`}
    >
      <strong tw="cursor-move absolute top-0 right-0 block w-10 h-10 p-2">
        <ArrowMoveColored />
      </strong>
      <h2 tw="font-sans font-medium text-lg mb-2">{title}</h2>
      <ul tw="grid grid-cols-4 gap-x-4 text-sm">
        {itemList.map((name, idx) => {
          return (
            <div key={idx} tw="h-6 flex justify-center items-center">
              <SimpleToggle
                label={name}
                checked={checkedStates?.[name]}
                onChange={onCheckedstate}
              />
            </div>
          )
        })}
      </ul>
    </div>
  )
}

const CNamesToggleMap = CNames.reduce((a,c) => (a[c] = false, a),{});
const ENamesToggleMap = ENames.reduce((a,c) => (a[c] = false, a),{});

const Controls = () => {
  const nodeRef = useRef(null)
  const nodeRef2 = useRef(null)
  const nodeRef3 = useRef(null)

  const snap = useSnapshot(state)

// ---------------------------
  const [selectedCity, selectedMat] = useMemo(()=>{
    return [snap.cityName, snap.matName]},[snap.cityName, snap.matName])
// -------------------------
  const [districtToggles,setDistrictToggles] = useState(CNamesToggleMap);
  const onDistrictSelect = e => {stateActions.selectedCity(e.target.name)};
// Listen to state changes, and update UI to reflect those changes 
  useEffect(() => {
    let district = snap.isolateCity;
    if(district){
      setDistrictToggles(prev => {
        let prevState = prev[district];
        let newM = CNames.reduce((a,c) => (a[c] = false, a),{});
        if(prevState) {
          newM[district] = false;
        }
        else {
          newM[district] = true;
        }
        return newM;
      })
    }
    else{
      setDistrictToggles(prev => {
        return CNames.reduce((a,c) => (a[c] = false, a),{});
      })
    }
  }, [snap.isolateCity])
// -------------------------
const [materialToggles,setMaterialToggles] = useState(ENamesToggleMap);
const onMaterialSelect = e => {stateActions.selectedMaterial(e.target.name)};
useEffect(() => {
  let material = snap.isolateMaterial
  if(material){
    setMaterialToggles(prev => {
      let prevState = prev[material];
      let newM = ENames.reduce((a,c) => (a[c] = false, a),{});
      if(prevState){ 
        newM[material] = false;
      }
      else {
        newM[material] = true;
      }
      return newM;
    })
  }
  else{
    setMaterialToggles(prev => {
      return ENames.reduce((a,c) => (a[c] = false, a),{});
    })
  }
}, [snap.isolateMaterial])
  return (
    <div tw="absolute w-full h-full text-white">
      <DraggableContainer nodeRef={nodeRef} bounds="parent" handle="strong">
        <div tw="absolute bottom-0 left-0 z-20 select-none" ref={nodeRef}>
          <Control title={"Districts"} itemList={CNames} checkedStates={districtToggles} onCheckedstate={onDistrictSelect}/>
        </div>
      </DraggableContainer>
      <DraggableContainer nodeRef={nodeRef2} bounds="parent" handle="strong" >
        <div tw="absolute bottom-1/4 left-0 z-20 select-none" ref={nodeRef2}>
          <Control title={"Elements"} itemList={ENames} checkedStates={materialToggles} onCheckedstate={onMaterialSelect} />
        </div>
      </DraggableContainer>
      {snap.isolateMaterial &&
            <DraggableContainer nodeRef={nodeRef3} bounds="parent" handle="strong" >
            <div tw="absolute w-1/3 bottom-0 right-0 z-20 select-none" ref={nodeRef3}>
            <div
      tw="relative
    m-5 p-5
    rounded-md
    cursor-auto
    filter drop-shadow-lg"
    css={css`background-color: rgba(34,34,34,0.45)`}
    >
      <strong tw="cursor-move absolute top-0 right-0 block w-10 h-10 p-2">
        <ArrowMoveColored />
      </strong>
      <h2 tw="text-4xl font-light">{ElementsData[snap.isolateMaterial]?.symbol}</h2>
      <h1 tw="text-2xl font-black">{ElementsData[snap.isolateMaterial]?.name}</h1>
      <br/>
      <p>{ElementsData[snap.isolateMaterial]?.description}</p>
            </div>
            </div>
          </DraggableContainer>
          }
    </div>
  )
}

export default Controls
