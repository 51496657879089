import * as THREE from "three"
import React, { Suspense, useRef, useEffect} from "react"
import { Canvas, useThree } from "@react-three/fiber"
import tw, { styled, css } from "twin.macro"
import {OrbitControls, Html, Loader} from "@react-three/drei"
import {ShadowPlane} from './ShadowPlane'
import {stateActions} from '../Store'

var minPan = new THREE.Vector3( - 50, - 50, - 50 );
var maxPan = new THREE.Vector3( 50, 50, 50 );
var _v = new THREE.Vector3();

const Controls=()=>{
  const controls = useRef({})
  const camera = useThree((state) => state.camera)
  useEffect(() => {
    const update = ()=>{
      _v.copy(controls.current.target);
      controls.current.target.clamp(minPan, maxPan);
      _v.sub(controls.current.target);
      camera.position.sub(_v);
      stateActions.updateCameraQuad(controls.current.getAzimuthalAngle())
    }
    if(controls.current){
      controls.current.addEventListener("change", update)
    }
    return () => {
      controls.current?.removeEventListener("change", update)
    }
  }, [])
  return(
    <OrbitControls
    ref={controls}
      maxPolarAngle={Math.PI / 2}
      enablePan={true}
      screenSpacePanning={true}
      minDistance={20}
      maxDistance={400}
      rotateSpeed={0.25}
      enableDamping={true}
    />
  )
}
export const Scene = props => {
  const light = useRef({})
  useEffect(() => {
    stateActions.initializeMatTag()
  }, [])
  return(
  <div
      css={[
        tw`h-screen flex flex-col items-center `,
      ]}
    >
  <Canvas
    concurrent
    colorManagement
    shadows
    frameloop={"demand"}
    dpr={[1, 2]}
    gl={{
      powerPreference: "low-power",
      alpha: true,
      physicallyCorrectLights: true,
    }}
    camera={{ 
      position: [-4.9, 77.25, 237.7], 
      near: 1, far: 600, fov: 40 }}
    onCreated={({ gl, camera, scene }) => {
      camera.lookAt(0, 0, 0)
      gl.physicallyCorrectLights = true
      gl.toneMapping = THREE.ACESFilmicToneMapping
      gl.outputEncoding = THREE.sRGBEncoding
      gl.toneMappingExposure = 0.8
      gl.localClippingEnabled = true // To enable the use of clipping planes
    }}
  >
    <hemisphereLight position={[0,200,0]} groundColor={'#080820'}/>
        <directionalLight ref={light} castShadow position={[0, 200, 40]} intensity={5} 
        shadow-camera-left={-200}
        shadow-camera-right={200}
        shadow-camera-top={200}
        shadow-camera-bottom={-200}
        />
    <Suspense
      fallback={
        <Html center>
          <Loader />
        </Html>
      }
    >
      <ShadowPlane opacity={0.4} position={[0, -4.95, 0]}
        width={200}
        height={200}/>
        {props.children}
    </Suspense>
    <Controls/>
  </Canvas>
  </div>
  )
    }
