import React, { useRef, useState, useEffect, useMemo  } from 'react'
import tw, { css } from "twin.macro"
import { useGLTF, Html, useTexture } from '@react-three/drei'
import { proxy, useSnapshot } from "valtio"
import state, {stateActions} from '../Store'
//https://www.robfaucher.com/swirling-soap-bubble-rainbow-weirdness/
import Bubble from '../../images/bubble2.jpg'
import{Vector2, RGBFormat, EquirectangularReflectionMapping, TextureLoader} from "three"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapModel from '!file-loader!./map.glb'

import {EColors, ENames, Targets} from '../../data/elements'
import {overrideCalculatePosition, ExplodeName} from '../../utils/misc'
// Used to figure out which threejs layer an object should be rendered to
const WhichLayer = ({cityName, matName, snapIsolateMaterial, snapIsolateCity, isSecondary})=>{
  let isolatedMatNum = ENames.indexOf(snapIsolateMaterial)
  if(snapIsolateMaterial != null && snapIsolateCity != null){
    if(matName == isolatedMatNum && cityName === snapIsolateCity) return 0;
    else return 1;
  }
  else if(snapIsolateMaterial != null){
    if(matName == isolatedMatNum) return 0;
    else return 1;
  } 
  else if(snapIsolateCity != null){
    if(cityName === snapIsolateCity || isSecondary) return 0;
    else return 1;
  } 
  return 0;
}
// Used to figure out what to render
const myData = (name, selectedCity, selectedMat, connectedCities)=>{
  let [cityName, matName] = ExplodeName(name)
  let isSelected = selectedCity === cityName
  let color = EColors[name.split("_")[2]]
  let isTop = matName == 16
  let isBottom = matName == 0
  let isSecondary = connectedCities?.some(e=> e == cityName && !isSelected)
  let isSelectedMaterial = ENames.indexOf(selectedMat) == matName
  let showTag = false
  if((isSecondary) && !selectedMat && isTop) showTag = true
  //else if(isSelected && selectedMat && isSelectedMaterial ) showTag = true
  return [cityName, matName, isSelected, color, isTop, isBottom, isSecondary, showTag]
}
// Used to handle drag
let mouseDownPos = [];
let mouseUpPos = [];
const v1 = new Vector2()
const v2 = new Vector2()
const HandleMouse = (e, type, currentlySelected)=>{
switch (type) {
  case "down":
    mouseDownPos = [e.clientX, e.clientY];
    break;
  case "up":
    if(mouseDownPos.length > 0){
      v1.fromArray(mouseDownPos)
      v2.fromArray([e.clientX, e.clientY])
      let dist = v1.distanceTo(v2)
      // console.log(v1, v2, dist)
      if(dist < 10)
      {
        const [cityName, matName] = ExplodeName(e.object.name)
        stateActions.selectedCity(cityName)
      }
      mouseDownPos = [];
    }
    break;
  default:
    break;
}
}
export default function Model(props) {
  const bubbleTexture = useMemo(() => {
    const texture = new TextureLoader().load(Bubble);
    texture.mapping = EquirectangularReflectionMapping;
    texture.format = RGBFormat;
    texture.needsUpdate = true;
    return texture;
}, [])
  const group = useRef()
  const snap = useSnapshot(state)
  const { nodes, materials } = useGLTF(mapModel)
  const [selectedCity, selectedMat, connectedCities] = useMemo(()=>{
    return [snap.isolateCity, snap.isolateMaterial, snap.isolateConnectedCities]},[snap.isolateCity, snap.isolateMaterial, snap.isolateConnectedCities])
  return (
    <group ref={group} {...props} dispose={null}
    onPointerDown={(e)=> (e.stopPropagation(), HandleMouse(e, "down", null))}
    onPointerUp={(e) => (e.stopPropagation(), HandleMouse(e, "up", snap.isolateCity))}
    onPointerMissed={(e) => stateActions.deselectAll()}
    >
      {Object.keys(nodes).map(node => {
        const [cityName, matName, isSelected, color, isTop, isBottom, isSecondary, showTag] = myData(nodes[node].name,selectedCity, selectedMat, connectedCities)
        return (
          <mesh 
          receiveShadow={false}
          castShadow={isBottom ? true : false}
          key={nodes[node].uuid} 
          geometry={nodes[node].geometry}  
          name={nodes[node].name} 
          layers={WhichLayer({cityName:cityName, matName:matName, snapIsolateMaterial:snap.isolateMaterial, snapIsolateCity:snap.isolateCity, isSecondary:isSecondary })}>
            {isSelected ? 
            <meshNormalMaterial attach="material" />
            : isSecondary ? 
            <meshPhysicalMaterial attach="material" color={!Object.is(color, undefined) ? color: "#FFF7D6"} transparent={true} opacity={0.4} envMap={bubbleTexture} roughness={0} metalness={0} clearcoat={1} reflectivity={1} clearcoatRoughness={0.15} envMapIntensity={0.5}/>
            :<meshPhysicalMaterial attach="material" color={!Object.is(color, undefined) ? color: "#FFF7D6"} transparent={false} opacity={1} envMap={bubbleTexture} roughness={0} metalness={0} clearcoat={1} reflectivity={0.5} clearcoatRoughness={0.15} envMapIntensity={0.5}/>}
      {showTag && <Html calculatePosition={overrideCalculatePosition}>
        <div className="content" css={[
        tw`absolute top-0 z-10 w-auto p-2 -mt-1 text-sm leading-tight text-black transform -translate-x-1/2 -translate-y-full bg-white rounded-lg shadow-lg pointer-events-none`,
        css`display:block;`,
        isSecondary && css`background-color:rgba(255,255,255,0.5);`
        ]}>
          {cityName}
        </div>
      </Html>}
            </mesh>
        )
})}
    </group>
  )
}

useGLTF.preload(mapModel)
