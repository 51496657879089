import * as THREE from "three"

// use to center tag to object's center
const v1 = new THREE.Vector3()
const overrideCalculatePosition = (el, camera, size) => {
  const element = el.parent
  const geo = element.geometry;
  if(Object.is(geo.boundingBox,undefined)) geo.computeBoundingBox();
  geo.boundingBox.getCenter(v1);
  element.localToWorld(v1);
  const objectPos = v1;
  objectPos.project(camera)
  const widthHalf = size.width / 2
  const heightHalf = size.height / 2
  return[objectPos.x * widthHalf + widthHalf, -(objectPos.y * heightHalf) + heightHalf]
}

// Get name and material given that the template is <E_Name_Material>
const ExplodeName = name => {
    if (name) {
      let split = name.split("_")
      return [split[1], split.length > 1 ? split[2] : null]
    } else return ["", ""]
  }

export {overrideCalculatePosition, ExplodeName}