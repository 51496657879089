import React, { useRef, useState, useEffect, useMemo, Suspense } from 'react'
import tw, { styled, css } from "twin.macro"
import { Html, Loader, Line } from "@react-three/drei"
import {LineCustom} from '../LineCustom'
import { useSnapshot } from "valtio"
import state from '../Store'
import {CatmullRomCurve3, Vector3} from 'three'
import {curves, dashedCurves, lines, dashedLines} from './crvs'
import {matTags} from './MatTags'
import {ENames} from '../../data/elements'
const v = new Vector3();
const partition = (input, len, applyFunc=null) => input.reduce(
    function(accumulator, currentValue, currentIndex, array) {
      if (currentIndex % len=== 0){
        let partition = array.slice(currentIndex, currentIndex + len)
        if(applyFunc) partition = applyFunc(partition)
        accumulator.push(partition);
      }
      return accumulator;
    }, [])
const toV3 = (x)=>{v.fromArray(x); return v.clone()}
// split them in threes and create vector3 arrays of each
const CurvesStore = curves.cs.map(x=>{return {cp: partition(x.c, 3, toV3), o: x.o}})
const dashedCurvesStore = dashedCurves.cs.map(x=>{return {cp: partition(x.c, 3, toV3), o: x.o}})
const linesStore = lines.cs.map(x=>{return {cp: partition(x.c, 3, toV3), o: x.o}})
const dashedLinesStore = dashedLines.cs.map(x=>{return {cp: partition(x.c, 3, toV3), o: x.o}})
const matTagsStore = matTags.cs.map(x=>{return {cp: partition(partition(x.c, 3, toV3),2), o: x.o}})

const Curve = (props)=>{
    const positions = useMemo(() => {
        const curve = new CatmullRomCurve3(props.pts,false, "catmullrom", 0.4);
        const result = curve.getPoints( 200 );
        return result;
    }, [props.pts])
return(
    <>
      <Line
        points={positions}
        {...props}
      />
    </>
)
}
const MyLine = (props)=>{
return(
    <>
      <Line
        points={props.pts}
        {...props}
      />
    </>
)
}
const MatTag = ({position,text,occlude=false})=>{
  return(
    <Html position={position} center distanceFactor={80} occlude={occlude}>
      <div tw="pointer-events-none flex select-none px-1 justify-center align-items[center] text-white font-bold">
            <p tw="text-xs">
      {text}
            </p>
        </div>
      </Html>
  )
}
// params for the curves and lines
const normal = {color: "#9934B7", lineWidth: 0.5}
const dashed = {dashed: true, ...normal, dashSize:0.5, gapSize:0.5}
export const Curves = ()=>{
    const snap = useSnapshot(state)
    const [show, setshow] = useState(true)
    const [filter, setfilter] = useState(false)

    useEffect(() => {
        if(snap.isolateCity && !snap.isolateMaterial) {
          setshow((p)=>false)
          setfilter((p)=>true)
        }
        else if(!snap.isolateCity && !snap.isolateMaterial){
          setshow((p)=>true)
          setfilter((p)=>false)
        }
        else {
          setshow((p)=>false)
          setfilter((p)=>false)

        }
    }, [snap.isolateCity, snap.isolateMaterial])
return(
    <>
        <Suspense
      fallback={
        <Html center>
          <Loader />
        </Html>
      }
    >
    {/* show all curves, lines, dashed curves and dashed lines in the main view */}
    {show && CurvesStore.map((cp,idx) =><Curve key={`${idx}_${cp.o}`} pts={cp.cp} {...normal}/>)}
    {show && dashedCurvesStore.map((cp,idx) =><Curve key={`${idx}_${cp.o}`} pts={cp.cp} {...dashed}/>)}
    {show && linesStore.map((cp,idx) =><MyLine key={`${idx}_${cp.o}`} pts={cp.cp} {...normal}/>)}
    {show && dashedLinesStore.map((cp,idx) =><MyLine key={`${idx}_${cp.o}`} pts={cp.cp} {...dashed}/>)}
    {/* show only curves and lines related to the isolated district */}
    {filter && CurvesStore.filter(cp=>snap.isolateCity == cp.o).map((cp,idx) =><Curve key={`${idx}_${cp.o}`} pts={cp.cp} {...normal}/>)}
    {filter && dashedCurvesStore.filter(cp=>snap.isolateCity == cp.o).map((cp,idx) =><Curve key={`${idx}_${cp.o}`} pts={cp.cp} {...dashed}/>)}
    {filter && linesStore.filter(cp=>snap.isolateCity == cp.o).map((cp,idx) =><MyLine key={`${idx}_${cp.o}`} pts={cp.cp} {...normal}/>)}
    {filter && dashedLinesStore.filter(cp=>snap.isolateCity == cp.o).map((cp,idx) =><MyLine key={`${idx}_${cp.o}`} pts={cp.cp} {...dashed}/>)}
    {/* show material tags next to the isolated district */}
    {filter && matTagsStore.filter(cp=>snap.isolateCity == cp.o).map((cp,idx) => cp.cp.map((endpts,idx2) => (
      <MatTag position={endpts[1]} text={ENames[idx2]} key={`${idx}_${idx2}_${cp.o}`} occlude/>)))}
    {/* Show material tags next to one of the districts, and depending on camera angle in main view */}
    {show && matTagsStore.filter(cp=>snap.showMatTagOn == cp.o).map((cp,idx) => cp.cp.map((endpts,idx2) => (
      <MatTag position={endpts[1]} text={ENames[idx2]} key={`${idx}_${idx2}_${cp.o}`}/>)))}
    </Suspense>
    </>
)
}
//    {/*<LineCustom key={`${idx}_${idx2}_${cp.o}`} points={endpts} {...normal}>*/}
// 
