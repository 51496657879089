import React, { useRef, useState, useEffect, useMemo, Suspense } from "react"
import tw, { styled, css } from "twin.macro"
import { Html } from "@react-three/drei"
import { useSnapshot } from "valtio"
import state from "../Store"
import {users} from '../../data/elements'
import {ENames} from '../../data/elements'

const getData = (isolatedCity, isolatedMaterial, user)=>{
    let userName = user.user
    let renderMaterial = false
    let materialValue = null
    let pos = null
    if(isolatedMaterial){
        renderMaterial = true
        let matData = user.mat[isolatedMaterial]
        pos = matData?.pos
        materialValue = matData?.value
    }
    else {
        pos = user.mat[ENames[ENames.length-1]].pos
    }
    return [userName, materialValue, renderMaterial, pos]
}
const Person=({pos, userName, renderMaterial, materialValue})=>{
    return(
        <Html
        // distanceFactor={100}
        position={pos}
        style={{pointerEvents:"none"}}
      >
        <div tw="pointer-events-none bg-white flex  transform -translate-y-full rounded-t-xl rounded-br-xl px-3 py-0.5 select-none justify-center align-items[center]">
            <p tw="text-sm font-bold">
                {userName}
            </p>
            {renderMaterial && (
                <>
            <p tw="ml-2 text-xs font-light w-max">
        {materialValue}
            </p>
            </>
            )}
        </div>
      </Html>
    )
}
export const People = () => {
    const snap = useSnapshot(state)
  return (
    <>
    {(snap.isolateCity) && (
        users.users.filter(x=>x.live == snap.isolateCity).map(user=>{
            const [userName, materialValue, renderMaterial, pos] = getData(snap.isolateCity, snap.isolateMaterial, user)
            return (
                <Person pos={pos} key={`${userName}_${snap.isolateMaterial}`} userName={userName} renderMaterial={renderMaterial} materialValue={materialValue}/>
            )
        })
    )}
    </>
  )
}
