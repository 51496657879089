import React, { useState, useEffect, useRef } from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components/macro"
const Toggle = ({label, checked, onChange}) => {
    return (
    <div tw="flex items-center w-full">
    <label tw="flex items-center cursor-pointer">
    {/* label */}
    <div tw="mr-3">
        {label}
      </div>
      <div tw="relative">
        <input type="checkbox" tw="sr-only" 
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        />
        {/* line */}
        <div tw="block w-14 h-8 rounded-full"
        css={[
            checked ? tw`bg-green-600` : tw`bg-gray-400`,
        ]}></div>
        {/* dot */}
        <div tw="absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
        css={[
          checked ? tw`transform translate-x-full` : tw`transform translate-x-0`,
      ]}></div>
      </div>
    </label>
  </div>
  )
  }
  const Slider = ({label,min,max,step=1, value, onChange, displayLabel=true, displayNum=true, disabled=false})=>{
  return (
      <div tw="flex items-center h-5 w-full">
      {displayLabel && <label>{label}</label>}
      <div tw="position[relative] px-1 flex-1">
          <div tw="w-full flex">
          <input tw="w-full flex-grow[3]" type="range" min={min} max={max} step={step} value={value} name={label} id={label} disabled={disabled}
          onChange={({ target: { value: radius } }) => onChange(radius) }/>
          {displayNum && <div tw="px-2">{value}</div>}
      </div>
      </div>
      </div>
  )
  }
  const SimpleToggle = ({label, checked, onChange}) => {
    return (
    <div tw="flex items-center w-full">
    <label tw="w-full flex justify-between items-center cursor-pointer ">
    <div tw="mr-2">
        {label}
      </div>
      <div tw="relative flex justify-center items-center">
        <input type="checkbox" tw="h-5 w-5 text-gray-600" 
        name={label}
        checked={checked}
        onChange={e => onChange(e)}
        />
      </div>
    </label>
  </div>
  )
  }

  export {Toggle, Slider, SimpleToggle}