export const matTags = {
  "cs": [
    {
      "c": [
        -14.429577800359628,
        2.4528775525107136,
        -19.025253937579453,
        -16.225045863014195,
        2.4528775525107136,
        -21.428646214339004,
        -14.429577800359628,
        5.56387526121754,
        -19.025253937579453,
        -16.225045863014195,
        5.56387526121754,
        -21.428646214339004,
        -14.429577800359628,
        6.3336654904544663,
        -19.025253937579453,
        -16.225045863014195,
        6.3336654904544663,
        -21.428646214339004,
        -14.429577800359628,
        6.7840714448885429,
        -19.025253937579453,
        -16.225045863014195,
        6.7840714448885429,
        -21.428646214339004,
        -14.429577800359628,
        7.4805825320324359,
        -19.025253937579453,
        -16.225045863014195,
        7.4805825320324359,
        -21.428646214339004,
        -14.429577800359628,
        8.5330973056674235,
        -19.025253937579453,
        -16.225045863014195,
        8.5330973056674235,
        -21.428646214339004,
        -14.429577800359628,
        9.9146854926281431,
        -19.025253937579453,
        -16.225045863014195,
        9.9146854926281431,
        -21.428646214339004,
        -14.429577800359628,
        11.47011189537486,
        -19.025253937579453,
        -16.225045863014195,
        11.47011189537486,
        -21.428646214339004,
        -14.429577800359628,
        15.242185641835615,
        -19.025253937579453,
        -16.225045863014195,
        15.242185641835615,
        -21.428646214339004,
        -14.429577800359628,
        19.131411304225381,
        -19.025253937579453,
        -16.225045863014195,
        19.131411304225381,
        -21.428646214339004,
        -14.429577800359628,
        20.476980227349912,
        -19.025253937579453,
        -16.225045863014195,
        20.476980227349912,
        -21.428646214339004,
        -14.429577800359628,
        21.433906523845906,
        -19.025253937579453,
        -16.225045863014195,
        21.433906523845906,
        -21.428646214339004,
        -14.429577800359628,
        22.85855347229413,
        -19.025253937579453,
        -16.225045863014195,
        22.85855347229413,
        -21.428646214339004,
        -14.429577800359628,
        24.381209711254556,
        -19.025253937579453,
        -16.225045863014195,
        24.381209711254556,
        -21.428646214339004,
        -14.429577800359628,
        25.717405409278015,
        -19.025253937579453,
        -16.225045863014195,
        25.717405409278015,
        -21.428646214339004,
        -14.429577800359628,
        26.988598195664849,
        -19.025253937579453,
        -16.225045863014195,
        26.988598195664849,
        -21.428646214339004,
        -14.429577800359628,
        28.152082160303948,
        -19.025253937579453,
        -16.225045863014195,
        28.152082160303948,
        -21.428646214339004,
        -14.429577800359628,
        29.321716356989246,
        -19.025253937579453,
        -16.225045863014195,
        29.321716356989246,
        -21.428646214339004
      ],
      "o": "10210"
    },
    {
      "c": [
        26.170356776194012,
        0.75351721334160782,
        65.43403535429691,
        27.289742976303067,
        0.75351721334160782,
        68.217374093114984,
        26.170356776194012,
        2.2219751741136005,
        65.43403535429691,
        27.289742976303067,
        2.2219751741136005,
        68.217374093114984,
        26.170356776194012,
        3.1149569252820708,
        65.43403535429691,
        27.289742976303067,
        3.1149569252820708,
        68.217374093114984,
        26.170356776194012,
        4.3544180052602535,
        65.43403535429691,
        27.289742976303067,
        4.3544180052602535,
        68.217374093114984,
        26.170356776194012,
        5.6975574126799025,
        65.43403535429691,
        27.289742976303067,
        5.6975574126799025,
        68.217374093114984,
        26.170356776194012,
        7.94107596013531,
        65.43403535429691,
        27.289742976303067,
        7.94107596013531,
        68.217374093114984,
        26.170356776194012,
        10.206435231466983,
        65.43403535429691,
        27.289742976303067,
        10.206435231466983,
        68.217374093114984,
        26.170356776194012,
        12.992643985243836,
        65.43403535429691,
        27.289742976303067,
        12.992643985243836,
        68.217374093114984,
        26.170356776194012,
        16.736528750781492,
        65.43403535429691,
        27.289742976303067,
        16.736528750781492,
        68.217374093114984,
        26.170356776194012,
        20.338479801441238,
        65.43403535429691,
        27.289742976303067,
        20.338479801441238,
        68.217374093114984,
        26.170356776194012,
        23.069412346125965,
        65.43403535429691,
        27.289742976303067,
        23.069412346125965,
        68.217374093114984,
        26.170356776194012,
        23.920527680459806,
        65.43403535429691,
        27.289742976303067,
        23.920527680459806,
        68.217374093114984,
        26.170356776194012,
        25.409050995536319,
        65.43403535429691,
        27.289742976303067,
        25.409050995536319,
        68.217374093114984,
        26.170356776194012,
        27.661825975288913,
        65.43403535429691,
        27.289742976303067,
        27.661825975288913,
        68.217374093114984,
        26.170356776194012,
        29.944412764305234,
        65.43403535429691,
        27.289742976303067,
        29.944412764305234,
        68.217374093114984,
        26.170356776194012,
        32.3442103195511,
        65.43403535429691,
        27.289742976303067,
        32.3442103195511,
        68.217374093114984,
        26.170356776194012,
        34.2213217934248,
        65.43403535429691,
        27.289742976303067,
        34.2213217934248,
        68.217374093114984,
        26.170356776194012,
        35.55794058754509,
        65.43403535429691,
        27.289742976303067,
        35.55794058754509,
        68.217374093114984
      ],
      "o": "10540"
    },
    {
      "c": [
        -61.833914346795609,
        1.4550862020863837,
        6.6758931370750272,
        -64.817594497716641,
        1.4550862020863837,
        6.9883875482247584,
        -61.833914346795609,
        4.6190418249877983,
        6.6758931370750272,
        -64.817594497716641,
        4.6190418249877983,
        6.9883875482247584,
        -61.833914346795609,
        6.4742278681164827,
        6.6758931370750272,
        -64.817594497716641,
        6.4742278681164827,
        6.9883875482247584,
        -61.833914346795609,
        6.9245593205743479,
        6.6758931370750272,
        -64.817594497716641,
        6.9245593205743479,
        6.9883875482247584,
        -61.833914346795609,
        7.5770557044872469,
        6.6758931370750272,
        -64.817594497716641,
        7.5770557044872469,
        6.9883875482247584,
        -61.833914346795609,
        9.0766405596164788,
        6.6758931370750272,
        -64.817594497716641,
        9.0766405596164788,
        6.9883875482247584,
        -61.833914346795609,
        11.156903270939509,
        6.6758931370750272,
        -64.817594497716641,
        11.156903270939509,
        6.9883875482247584,
        -61.833914346795609,
        13.639831070341168,
        6.6758931370750272,
        -64.817594497716641,
        13.639831070341168,
        6.9883875482247584,
        -61.833914346795609,
        16.257084117255303,
        6.6758931370750272,
        -64.817594497716641,
        16.257084117255303,
        6.9883875482247584,
        -61.833914346795609,
        18.480868186829944,
        6.6758931370750272,
        -64.817594497716641,
        18.480868186829944,
        6.9883875482247584,
        -61.833914346795609,
        20.004282092106411,
        6.6758931370750272,
        -64.817594497716641,
        20.004282092106411,
        6.9883875482247584,
        -61.833914346795609,
        21.109837952940524,
        6.6758931370750272,
        -64.817594497716641,
        21.109837952940524,
        6.9883875482247584,
        -61.833914346795609,
        22.530154395763155,
        6.6758931370750272,
        -64.817594497716641,
        22.530154395763155,
        6.9883875482247584,
        -61.833914346795609,
        24.982956538500471,
        6.6758931370750272,
        -64.817594497716641,
        24.982956538500471,
        6.9883875482247584,
        -61.833914346795609,
        27.531765809397715,
        6.6758931370750272,
        -64.817594497716641,
        27.531765809397715,
        6.9883875482247584,
        -61.833914346795609,
        29.179624935827249,
        6.6758931370750272,
        -64.817594497716641,
        29.179624935827249,
        6.9883875482247584,
        -61.833914346795609,
        30.58699518902586,
        6.6758931370750272,
        -64.817594497716641,
        30.58699518902586,
        6.9883875482247584,
        -61.833914346795609,
        32.252166446098734,
        6.6758931370750272,
        -64.817594497716641,
        32.252166446098734,
        6.9883875482247584
      ],
      "o": "11130"
    },
    {
      "c": [
        -40.462247399942669,
        0.419943927607812,
        4.30272189945293,
        -43.4469431560954,
        0.419943927607812,
        4.6053623514812294,
        -40.462247399942669,
        1.6624888102890947,
        4.30272189945293,
        -43.4469431560954,
        1.6624888102890947,
        4.6053623514812294,
        -40.462247399942669,
        2.6528637394085175,
        4.30272189945293,
        -43.4469431560954,
        2.6528637394085175,
        4.6053623514812294,
        -40.462247399942669,
        3.115037954007037,
        4.30272189945293,
        -43.4469431560954,
        3.115037954007037,
        4.6053623514812294,
        -40.462247399942669,
        3.7176706817477978,
        4.30272189945293,
        -43.4469431560954,
        3.7176706817477978,
        4.6053623514812294,
        -40.462247399942669,
        4.989472161801606,
        4.30272189945293,
        -43.4469431560954,
        4.989472161801606,
        4.6053623514812294,
        -40.462247399942669,
        7.3420665170547927,
        4.30272189945293,
        -43.4469431560954,
        7.3420665170547927,
        4.6053623514812294,
        -40.462247399942669,
        10.195595319141816,
        4.30272189945293,
        -43.4469431560954,
        10.195595319141816,
        4.6053623514812294,
        -40.462247399942669,
        12.76556653204468,
        4.30272189945293,
        -43.4469431560954,
        12.76556653204468,
        4.6053623514812294,
        -40.462247399942669,
        15.392833896695507,
        4.30272189945293,
        -43.4469431560954,
        15.392833896695507,
        4.6053623514812294,
        -40.462247399942669,
        17.264686657969424,
        4.30272189945293,
        -43.4469431560954,
        17.264686657969424,
        4.6053623514812294,
        -40.462247399942669,
        18.666985142182504,
        4.30272189945293,
        -43.4469431560954,
        18.666985142182504,
        4.6053623514812294,
        -40.462247399942669,
        20.32813767500922,
        4.30272189945293,
        -43.4469431560954,
        20.32813767500922,
        4.6053623514812294,
        -40.462247399942669,
        21.921170734539025,
        4.30272189945293,
        -43.4469431560954,
        21.921170734539025,
        4.6053623514812294,
        -40.462247399942669,
        23.543559200469886,
        4.30272189945293,
        -43.4469431560954,
        23.543559200469886,
        4.6053623514812294,
        -40.462247399942669,
        25.073322051466917,
        4.30272189945293,
        -43.4469431560954,
        25.073322051466917,
        4.6053623514812294,
        -40.462247399942669,
        26.498210286147341,
        4.30272189945293,
        -43.4469431560954,
        26.498210286147341,
        4.6053623514812294,
        -40.462247399942669,
        27.851833060720288,
        4.30272189945293,
        -43.4469431560954,
        27.851833060720288,
        4.6053623514812294
      ],
      "o": "11000"
    },
    {
      "c": [
        -57.711227914406344,
        1.1478873985147549,
        39.41121128478008,
        -60.191931253541583,
        1.1478873985147549,
        41.098253353966044,
        -57.711227914406344,
        2.6121122853422287,
        39.41121128478008,
        -60.191931253541583,
        2.6121122853422287,
        41.098253353966044,
        -57.711227914406344,
        3.0587913566747726,
        39.41121128478008,
        -60.191931253541583,
        3.0587913566747726,
        41.098253353966044,
        -57.711227914406344,
        3.5422948140401966,
        39.41121128478008,
        -60.191931253541583,
        3.5422948140401966,
        41.098253353966044,
        -57.711227914406344,
        5.4954304208867066,
        39.41121128478008,
        -60.191931253541583,
        5.4954304208867066,
        41.098253353966044,
        -57.711227914406344,
        8.0487305331751511,
        39.41121128478008,
        -60.191931253541583,
        8.0487305331751511,
        41.098253353966044,
        -57.711227914406344,
        10.20344012680993,
        39.41121128478008,
        -60.191931253541583,
        10.20344012680993,
        41.098253353966044,
        -57.711227914406344,
        12.425997605865321,
        39.41121128478008,
        -60.191931253541583,
        12.425997605865321,
        41.098253353966044,
        -57.711227914406344,
        14.535872473677173,
        39.41121128478008,
        -60.191931253541583,
        14.535872473677173,
        41.098253353966044,
        -57.711227914406344,
        16.5646216387402,
        39.41121128478008,
        -60.191931253541583,
        16.5646216387402,
        41.098253353966044,
        -57.711227914406344,
        18.050936690199165,
        39.41121128478008,
        -60.191931253541583,
        18.050936690199165,
        41.098253353966044,
        -57.711227914406344,
        20.032654573275121,
        39.41121128478008,
        -60.191931253541583,
        20.032654573275121,
        41.098253353966044,
        -57.711227914406344,
        22.32422216858,
        39.41121128478008,
        -60.191931253541583,
        22.32422216858,
        41.098253353966044,
        -57.711227914406344,
        23.648077083794526,
        39.41121128478008,
        -60.191931253541583,
        23.648077083794526,
        41.098253353966044,
        -57.711227914406344,
        24.727140167101719,
        39.41121128478008,
        -60.191931253541583,
        24.727140167101719,
        41.098253353966044,
        -57.711227914406344,
        26.095117907322795,
        39.41121128478008,
        -60.191931253541583,
        26.095117907322795,
        41.098253353966044,
        -57.711227914406344,
        28.1769720810284,
        39.41121128478008,
        -60.191931253541583,
        28.1769720810284,
        41.098253353966044,
        -57.711227914406344,
        31.935436632883558,
        39.41121128478008,
        -60.191931253541583,
        31.935436632883558,
        41.098253353966044
      ],
      "o": "10160"
    },
    {
      "c": [
        -44.328904973784219,
        0.89487485264463973,
        70.731819545102482,
        -45.923767435436233,
        0.89487485264463973,
        73.272766952746579,
        -44.328904973784219,
        1.9719711933416177,
        70.731819545102482,
        -45.923767435436233,
        1.9719711933416177,
        73.272766952746579,
        -44.328904973784219,
        2.2679623484681093,
        70.731819545102482,
        -45.923767435436233,
        2.2679623484681093,
        73.272766952746579,
        -44.328904973784219,
        2.7805873918516912,
        70.731819545102482,
        -45.923767435436233,
        2.7805873918516912,
        73.272766952746579,
        -44.328904973784219,
        4.3529214859423231,
        70.731819545102482,
        -45.923767435436233,
        4.3529214859423231,
        73.272766952746579,
        -44.328904973784219,
        7.3403601087660668,
        70.731819545102482,
        -45.923767435436233,
        7.3403601087660668,
        73.272766952746579,
        -44.328904973784219,
        9.7046916556074834,
        70.731819545102482,
        -45.923767435436233,
        9.7046916556074834,
        73.272766952746579,
        -44.328904973784219,
        12.478146797977812,
        70.731819545102482,
        -45.923767435436233,
        12.478146797977812,
        73.272766952746579,
        -44.328904973784219,
        15.588149028808397,
        70.731819545102482,
        -45.923767435436233,
        15.588149028808397,
        73.272766952746579,
        -44.328904973784219,
        17.905349185545127,
        70.731819545102482,
        -45.923767435436233,
        17.905349185545127,
        73.272766952746579,
        -44.328904973784219,
        19.682047897590067,
        70.731819545102482,
        -45.923767435436233,
        19.682047897590067,
        73.272766952746579,
        -44.328904973784219,
        20.854622981527886,
        70.731819545102482,
        -45.923767435436233,
        20.854622981527886,
        73.272766952746579,
        -44.328904973784219,
        22.588635316752963,
        70.731819545102482,
        -45.923767435436233,
        22.588635316752963,
        73.272766952746579,
        -44.328904973784219,
        24.092262874495489,
        70.731819545102482,
        -45.923767435436233,
        24.092262874495489,
        73.272766952746579,
        -44.328904973784219,
        25.293017736171109,
        70.731819545102482,
        -45.923767435436233,
        25.293017736171109,
        73.272766952746579,
        -44.328904973784219,
        26.731426764792907,
        70.731819545102482,
        -45.923767435436233,
        26.731426764792907,
        73.272766952746579,
        -44.328904973784219,
        28.365098961964364,
        70.731819545102482,
        -45.923767435436233,
        28.365098961964364,
        73.272766952746579,
        -44.328904973784219,
        29.895943155930382,
        70.731819545102482,
        -45.923767435436233,
        29.895943155930382,
        73.272766952746579
      ],
      "o": "10150"
    },
    {
      "c": [
        -28.986997247264011,
        0.89134453893790155,
        52.018886467316378,
        -30.449319453274914,
        0.89134453893790155,
        54.638354689266663,
        -28.986997247264011,
        1.9782149598016474,
        52.018886467316378,
        -30.449319453274914,
        1.9782149598016474,
        54.638354689266663,
        -28.986997247264011,
        2.2891155881420544,
        52.018886467316378,
        -30.449319453274914,
        2.2891155881420544,
        54.638354689266663,
        -28.986997247264011,
        2.819893053680909,
        52.018886467316378,
        -30.449319453274914,
        2.819893053680909,
        54.638354689266663,
        -28.986997247264011,
        4.3865030952022783,
        52.018886467316378,
        -30.449319453274914,
        4.3865030952022783,
        54.638354689266663,
        -28.986997247264011,
        7.3553627094502456,
        52.018886467316378,
        -30.449319453274914,
        7.3553627094502456,
        54.638354689266663,
        -28.986997247264011,
        9.71722207411889,
        52.018886467316378,
        -30.449319453274914,
        9.71722207411889,
        54.638354689266663,
        -28.986997247264011,
        12.490996197639465,
        52.018886467316378,
        -30.449319453274914,
        12.490996197639465,
        54.638354689266663,
        -28.986997247264011,
        15.616829785381187,
        52.018886467316378,
        -30.449319453274914,
        15.616829785381187,
        54.638354689266663,
        -28.986997247264011,
        17.966115251183396,
        52.018886467316378,
        -30.449319453274914,
        17.966115251183396,
        54.638354689266663,
        -28.986997247264011,
        19.76664645674423,
        52.018886467316378,
        -30.449319453274914,
        19.76664645674423,
        54.638354689266663,
        -28.986997247264011,
        20.93119285712811,
        52.018886467316378,
        -30.449319453274914,
        20.93119285712811,
        54.638354689266663,
        -28.986997247264011,
        22.659073491890986,
        52.018886467316378,
        -30.449319453274914,
        22.659073491890986,
        54.638354689266663,
        -28.986997247264011,
        24.18141168880171,
        52.018886467316378,
        -30.449319453274914,
        24.18141168880171,
        54.638354689266663,
        -28.986997247264011,
        25.409183846959444,
        52.018886467316378,
        -30.449319453274914,
        25.409183846959444,
        54.638354689266663,
        -28.986997247264011,
        26.871603172132794,
        52.018886467316378,
        -30.449319453274914,
        26.871603172132794,
        54.638354689266663,
        -28.986997247264011,
        28.511355106203212,
        52.018886467316378,
        -30.449319453274914,
        28.511355106203212,
        54.638354689266663,
        -28.986997247264011,
        30.037349158911248,
        52.018886467316378,
        -30.449319453274914,
        30.037349158911248,
        54.638354689266663
      ],
      "o": "10140"
    },
    {
      "c": [
        11.882094813393021,
        0.66213584401719061,
        10.916745964403814,
        14.115666558595841,
        0.66213584401719061,
        12.919533336800502,
        11.882094813393021,
        2.2096242020637997,
        10.916745964403814,
        14.115666558595841,
        2.2096242020637997,
        12.919533336800502,
        11.882094813393021,
        3.2817084692662686,
        10.916745964403814,
        14.115666558595841,
        3.2817084692662686,
        12.919533336800502,
        11.882094813393021,
        4.4911641913593385,
        10.916745964403814,
        14.115666558595841,
        4.4911641913593385,
        12.919533336800502,
        11.882094813393021,
        6.1196449155297019,
        10.916745964403814,
        14.115666558595841,
        6.1196449155297019,
        12.919533336800502,
        11.882094813393021,
        8.15073813814843,
        10.916745964403814,
        14.115666558595841,
        8.15073813814843,
        12.919533336800502,
        11.882094813393021,
        11.693460242934538,
        10.916745964403814,
        14.115666558595841,
        11.693460242934538,
        12.919533336800502,
        11.882094813393021,
        15.736016794788085,
        10.916745964403814,
        14.115666558595841,
        15.736016794788085,
        12.919533336800502,
        11.882094813393021,
        18.771782501827985,
        10.916745964403814,
        14.115666558595841,
        18.771782501827985,
        12.919533336800502,
        11.882094813393021,
        21.968219295976041,
        10.916745964403814,
        14.115666558595841,
        21.968219295976041,
        12.919533336800502,
        11.882094813393021,
        24.40444581332055,
        10.916745964403814,
        14.115666558595841,
        24.40444581332055,
        12.919533336800502,
        11.882094813393021,
        25.833111110374716,
        10.916745964403814,
        14.115666558595841,
        25.833111110374716,
        12.919533336800502,
        11.882094813393021,
        28.318599836950217,
        10.916745964403814,
        14.115666558595841,
        28.318599836950217,
        12.919533336800502,
        11.882094813393021,
        30.814987513971538,
        10.916745964403814,
        14.115666558595841,
        30.814987513971538,
        12.919533336800502,
        11.882094813393021,
        32.890497625279387,
        10.916745964403814,
        14.115666558595841,
        32.890497625279387,
        12.919533336800502,
        11.882094813393021,
        35.457476363006712,
        10.916745964403814,
        14.115666558595841,
        35.457476363006712,
        12.919533336800502,
        11.882094813393021,
        37.719501357869476,
        10.916745964403814,
        14.115666558595841,
        37.719501357869476,
        12.919533336800502,
        11.882094813393021,
        39.30988890857008,
        10.916745964403814,
        14.115666558595841,
        39.30988890857008,
        12.919533336800502
      ],
      "o": "10230"
    },
    {
      "c": [
        -23.623563668009751,
        1.6834746709433779,
        19.9137886752441,
        -25.92470257453698,
        1.6834746709433779,
        21.838563917413083,
        -23.623563668009751,
        4.8122259188509151,
        19.9137886752441,
        -25.92470257453698,
        4.8122259188509151,
        21.838563917413083,
        -23.623563668009751,
        6.5141731356721335,
        19.9137886752441,
        -25.92470257453698,
        6.5141731356721335,
        21.838563917413083,
        -23.623563668009751,
        7.2008333085733724,
        19.9137886752441,
        -25.92470257453698,
        7.2008333085733724,
        21.838563917413083,
        -23.623563668009751,
        10.236095407296773,
        19.9137886752441,
        -25.92470257453698,
        10.236095407296773,
        21.838563917413083,
        -23.623563668009751,
        15.126392291355138,
        19.9137886752441,
        -25.92470257453698,
        15.126392291355138,
        21.838563917413083,
        -23.623563668009751,
        19.655003429798981,
        19.9137886752441,
        -25.92470257453698,
        19.655003429798981,
        21.838563917413083,
        -23.623563668009751,
        22.659390281806637,
        19.9137886752441,
        -25.92470257453698,
        22.659390281806637,
        21.838563917413083,
        -23.623563668009751,
        24.071429242038814,
        19.9137886752441,
        -25.92470257453698,
        24.071429242038814,
        21.838563917413083,
        -23.623563668009751,
        25.630763367347804,
        19.9137886752441,
        -25.92470257453698,
        25.630763367347804,
        21.838563917413083,
        -23.623563668009751,
        28.784066397178524,
        19.9137886752441,
        -25.92470257453698,
        28.784066397178524,
        21.838563917413083,
        -23.623563668009751,
        33.781572313676989,
        19.9137886752441,
        -25.92470257453698,
        33.781572313676989,
        21.838563917413083,
        -23.623563668009751,
        38.717337753816388,
        19.9137886752441,
        -25.92470257453698,
        38.717337753816388,
        21.838563917413083,
        -23.623563668009751,
        42.263631070137777,
        19.9137886752441,
        -25.92470257453698,
        42.263631070137777,
        21.838563917413083,
        -23.623563668009751,
        46.024437237887234,
        19.9137886752441,
        -25.92470257453698,
        46.024437237887234,
        21.838563917413083,
        -23.623563668009751,
        49.686420979269855,
        19.9137886752441,
        -25.92470257453698,
        49.686420979269855,
        21.838563917413083,
        -23.623563668009751,
        52.117596763480933,
        19.9137886752441,
        -25.92470257453698,
        52.117596763480933,
        21.838563917413083,
        -23.623563668009751,
        54.855187030143924,
        19.9137886752441,
        -25.92470257453698,
        54.855187030143924,
        21.838563917413083
      ],
      "o": "10400"
    },
    {
      "c": [
        -15.102468380282966,
        1.4607240921810578,
        20.675305303506136,
        -16.878810773395294,
        1.4607240921810578,
        23.092867650671554,
        -15.102468380282966,
        3.8930494919704248,
        20.675305303506136,
        -16.878810773395294,
        3.8930494919704248,
        23.092867650671554,
        -15.102468380282966,
        5.256327002683129,
        20.675305303506136,
        -16.878810773395294,
        5.256327002683129,
        23.092867650671554,
        -15.102468380282966,
        6.41720837538774,
        20.675305303506136,
        -16.878810773395294,
        6.41720837538774,
        23.092867650671554,
        -15.102468380282966,
        7.7112021472568149,
        20.675305303506136,
        -16.878810773395294,
        7.7112021472568149,
        23.092867650671554,
        -15.102468380282966,
        9.6864029230087638,
        20.675305303506136,
        -16.878810773395294,
        9.6864029230087638,
        23.092867650671554,
        -15.102468380282966,
        12.751290325628553,
        20.675305303506136,
        -16.878810773395294,
        12.751290325628553,
        23.092867650671554,
        -15.102468380282966,
        16.090386383732071,
        20.675305303506136,
        -16.878810773395294,
        16.090386383732071,
        23.092867650671554,
        -15.102468380282966,
        18.521105264226868,
        20.675305303506136,
        -16.878810773395294,
        18.521105264226868,
        23.092867650671554,
        -15.102468380282966,
        21.107267572154569,
        20.675305303506136,
        -16.878810773395294,
        21.107267572154569,
        23.092867650671554,
        -15.102468380282966,
        23.453189555461059,
        20.675305303506136,
        -16.878810773395294,
        23.453189555461059,
        23.092867650671554,
        -15.102468380282966,
        25.171639993023415,
        20.675305303506136,
        -16.878810773395294,
        25.171639993023415,
        23.092867650671554,
        -15.102468380282966,
        27.504006498275224,
        20.675305303506136,
        -16.878810773395294,
        27.504006498275224,
        23.092867650671554,
        -15.102468380282966,
        29.830632344529892,
        20.675305303506136,
        -16.878810773395294,
        29.830632344529892,
        23.092867650671554,
        -15.102468380282966,
        32.061058985260743,
        20.675305303506136,
        -16.878810773395294,
        32.061058985260743,
        23.092867650671554,
        -15.102468380282966,
        34.838157086447637,
        20.675305303506136,
        -16.878810773395294,
        34.838157086447637,
        23.092867650671554,
        -15.102468380282966,
        37.555127459936969,
        20.675305303506136,
        -16.878810773395294,
        37.555127459936969,
        23.092867650671554,
        -15.102468380282966,
        39.425740107643847,
        20.675305303506136,
        -16.878810773395294,
        39.425740107643847,
        23.092867650671554
      ],
      "o": "10310"
    },
    {
      "c": [
        -58.725486549324465,
        0.94915048141425429,
        23.140382867654768,
        -61.519553840076767,
        0.94915048141425429,
        24.232714307625304,
        -58.725486549324465,
        2.9863209210121604,
        23.140382867654768,
        -61.519553840076767,
        2.9863209210121604,
        24.232714307625304,
        -58.725486549324465,
        4.1898426389584724,
        23.140382867654768,
        -61.519553840076767,
        4.1898426389584724,
        24.232714307625304,
        -58.725486549324465,
        4.6113457395419406,
        23.140382867654768,
        -61.519553840076767,
        4.6113457395419406,
        24.232714307625304,
        -58.725486549324465,
        5.76551401463975,
        23.140382867654768,
        -61.519553840076767,
        5.76551401463975,
        24.232714307625304,
        -58.725486549324465,
        7.9514419084177046,
        23.140382867654768,
        -61.519553840076767,
        7.9514419084177046,
        24.232714307625304,
        -58.725486549324465,
        10.308656325296459,
        23.140382867654768,
        -61.519553840076767,
        10.308656325296459,
        24.232714307625304,
        -58.725486549324465,
        12.858188891429968,
        23.140382867654768,
        -61.519553840076767,
        12.858188891429968,
        24.232714307625304,
        -58.725486549324465,
        15.243349500438763,
        23.140382867654768,
        -61.519553840076767,
        15.243349500438763,
        24.232714307625304,
        -58.725486549324465,
        17.125356693250009,
        23.140382867654768,
        -61.519553840076767,
        17.125356693250009,
        24.232714307625304,
        -58.725486549324465,
        18.507651960310994,
        23.140382867654768,
        -61.519553840076767,
        18.507651960310994,
        24.232714307625304,
        -58.725486549324465,
        19.472895557260365,
        23.140382867654768,
        -61.519553840076767,
        19.472895557260365,
        24.232714307625304,
        -58.725486549324465,
        20.685247559330278,
        23.140382867654768,
        -61.519553840076767,
        20.685247559330278,
        24.232714307625304,
        -58.725486549324465,
        22.580540144880764,
        23.140382867654768,
        -61.519553840076767,
        22.580540144880764,
        24.232714307625304,
        -58.725486549324465,
        24.525731274188466,
        23.140382867654768,
        -61.519553840076767,
        24.525731274188466,
        24.232714307625304,
        -58.725486549324465,
        25.935766945147719,
        23.140382867654768,
        -61.519553840076767,
        25.935766945147719,
        24.232714307625304,
        -58.725486549324465,
        27.420877819287,
        23.140382867654768,
        -61.519553840076767,
        27.420877819287,
        24.232714307625304,
        -58.725486549324465,
        29.124673029455096,
        23.140382867654768,
        -61.519553840076767,
        29.124673029455096,
        24.232714307625304
      ],
      "o": "10170"
    },
    {
      "c": [
        10.450971326764137,
        0.78351114550347878,
        38.360846112282211,
        11.24792115009328,
        0.78351114550347878,
        41.253054780254779,
        10.450971326764137,
        2.8018367328142775,
        38.360846112282211,
        11.24792115009328,
        2.8018367328142775,
        41.253054780254779,
        10.450971326764137,
        4.1814292372164577,
        38.360846112282211,
        11.24792115009328,
        4.1814292372164577,
        41.253054780254779,
        10.450971326764137,
        5.0349307947202657,
        38.360846112282211,
        11.24792115009328,
        5.0349307947202657,
        41.253054780254779,
        10.450971326764137,
        6.3350813097326206,
        38.360846112282211,
        11.24792115009328,
        6.3350813097326206,
        41.253054780254779,
        10.450971326764137,
        8.5949912310547933,
        38.360846112282211,
        11.24792115009328,
        8.5949912310547933,
        41.253054780254779,
        10.450971326764137,
        11.157220686922184,
        38.360846112282211,
        11.24792115009328,
        11.157220686922184,
        41.253054780254779,
        10.450971326764137,
        13.973894175265194,
        38.360846112282211,
        11.24792115009328,
        13.973894175265194,
        41.253054780254779,
        10.450971326764137,
        18.266167014440153,
        38.360846112282211,
        11.24792115009328,
        18.266167014440153,
        41.253054780254779,
        10.450971326764137,
        22.774161640472279,
        38.360846112282211,
        11.24792115009328,
        22.774161640472279,
        41.253054780254779,
        10.450971326764137,
        25.331493311380932,
        38.360846112282211,
        11.24792115009328,
        25.331493311380932,
        41.253054780254779,
        10.450971326764137,
        26.804090357785022,
        38.360846112282211,
        11.24792115009328,
        26.804090357785022,
        41.253054780254779,
        10.450971326764137,
        28.992886546343712,
        38.360846112282211,
        11.24792115009328,
        28.992886546343712,
        41.253054780254779,
        10.450971326764137,
        31.896910221031149,
        38.360846112282211,
        11.24792115009328,
        31.896910221031149,
        41.253054780254779,
        10.450971326764137,
        34.624625657691055,
        38.360846112282211,
        11.24792115009328,
        34.624625657691055,
        41.253054780254779,
        10.450971326764137,
        36.854875497093488,
        38.360846112282211,
        11.24792115009328,
        36.854875497093488,
        41.253054780254779,
        10.450971326764137,
        38.659940429636869,
        38.360846112282211,
        11.24792115009328,
        38.659940429636869,
        41.253054780254779,
        10.450971326764137,
        39.975614777436711,
        38.360846112282211,
        11.24792115009328,
        39.975614777436711,
        41.253054780254779
      ],
      "o": "10250"
    },
    {
      "c": [
        50.972129767514495,
        0.47610530939186685,
        58.774054526197339,
        52.94299983702458,
        0.47610530939186685,
        61.0358394782008,
        50.972129767514495,
        1.9870760385971389,
        58.774054526197339,
        52.94299983702458,
        1.9870760385971389,
        61.0358394782008,
        50.972129767514495,
        3.1504499171753837,
        58.774054526197339,
        52.94299983702458,
        3.1504499171753837,
        61.0358394782008,
        50.972129767514495,
        3.9346629151194534,
        58.774054526197339,
        52.94299983702458,
        3.9346629151194534,
        61.0358394782008,
        50.972129767514495,
        6.3794162462479118,
        58.774054526197339,
        52.94299983702458,
        6.3794162462479118,
        61.0358394782008,
        50.972129767514495,
        9.42558997327442,
        58.774054526197339,
        52.94299983702458,
        9.42558997327442,
        61.0358394782008,
        50.972129767514495,
        13.168003669118789,
        58.774054526197339,
        52.94299983702458,
        13.168003669118789,
        61.0358394782008,
        50.972129767514495,
        16.923418648485555,
        58.774054526197339,
        52.94299983702458,
        16.923418648485555,
        61.0358394782008,
        50.972129767514495,
        18.94786447923196,
        58.774054526197339,
        52.94299983702458,
        18.94786447923196,
        61.0358394782008,
        50.972129767514495,
        20.942053492271867,
        58.774054526197339,
        52.94299983702458,
        20.942053492271867,
        61.0358394782008,
        50.972129767514495,
        22.560396688714626,
        58.774054526197339,
        52.94299983702458,
        22.560396688714626,
        61.0358394782008,
        50.972129767514495,
        24.327349164923824,
        58.774054526197339,
        52.94299983702458,
        24.327349164923824,
        61.0358394782008,
        50.972129767514495,
        27.341342389393397,
        58.774054526197339,
        52.94299983702458,
        27.341342389393397,
        61.0358394782008,
        50.972129767514495,
        30.695626583144545,
        58.774054526197339,
        52.94299983702458,
        30.695626583144545,
        61.0358394782008,
        50.972129767514495,
        33.1522938132796,
        58.774054526197339,
        52.94299983702458,
        33.1522938132796,
        61.0358394782008,
        50.972129767514495,
        34.795331597534386,
        58.774054526197339,
        52.94299983702458,
        34.795331597534386,
        61.0358394782008,
        50.972129767514495,
        36.83279261049119,
        58.774054526197339,
        52.94299983702458,
        36.83279261049119,
        61.0358394782008,
        50.972129767514495,
        38.692100991958995,
        58.774054526197339,
        52.94299983702458,
        38.692100991958995,
        61.0358394782008
      ],
      "o": "10560"
    },
    {
      "c": [
        41.696082790370546,
        0.65293085877794355,
        31.684454815271565,
        44.091692009490252,
        0.65293085877794355,
        33.490294362204978,
        41.696082790370546,
        2.3792471633380332,
        31.684454815271565,
        44.091692009490252,
        2.3792471633380332,
        33.490294362204978,
        41.696082790370546,
        3.5671444786548125,
        31.684454815271565,
        44.091692009490252,
        3.5671444786548125,
        33.490294362204978,
        41.696082790370546,
        4.2243953094806193,
        31.684454815271565,
        44.091692009490252,
        4.2243953094806193,
        33.490294362204978,
        41.696082790370546,
        6.7623496737474005,
        31.684454815271565,
        44.091692009490252,
        6.7623496737474005,
        33.490294362204978,
        41.696082790370546,
        9.80940443359762,
        31.684454815271565,
        44.091692009490252,
        9.80940443359762,
        33.490294362204978,
        41.696082790370546,
        13.638967911731077,
        31.684454815271565,
        44.091692009490252,
        13.638967911731077,
        33.490294362204978,
        41.696082790370546,
        17.37515793786698,
        31.684454815271565,
        44.091692009490252,
        17.37515793786698,
        33.490294362204978,
        41.696082790370546,
        19.213641201389681,
        31.684454815271565,
        44.091692009490252,
        19.213641201389681,
        33.490294362204978,
        41.696082790370546,
        21.055710952483111,
        31.684454815271565,
        44.091692009490252,
        21.055710952483111,
        33.490294362204978,
        41.696082790370546,
        22.39251055476079,
        31.684454815271565,
        44.091692009490252,
        22.39251055476079,
        33.490294362204978,
        41.696082790370546,
        24.2724117833067,
        31.684454815271565,
        44.091692009490252,
        24.2724117833067,
        33.490294362204978,
        41.696082790370546,
        27.47151361957518,
        31.684454815271565,
        44.091692009490252,
        27.47151361957518,
        33.490294362204978,
        41.696082790370546,
        30.912367231481408,
        31.684454815271565,
        44.091692009490252,
        30.912367231481408,
        33.490294362204978,
        41.696082790370546,
        33.309844832752106,
        31.684454815271565,
        44.091692009490252,
        33.309844832752106,
        33.490294362204978,
        41.696082790370546,
        34.751274008712045,
        31.684454815271565,
        44.091692009490252,
        34.751274008712045,
        33.490294362204978,
        41.696082790370546,
        36.741415623635959,
        31.684454815271565,
        44.091692009490252,
        36.741415623635959,
        33.490294362204978,
        41.696082790370546,
        38.652101569605506,
        31.684454815271565,
        44.091692009490252,
        38.652101569605506,
        33.490294362204978
      ],
      "o": "10520"
    },
    {
      "c": [
        -16.796183786103345,
        0.87742730370336419,
        28.776253270099716,
        -18.312380543042117,
        0.87742730370336419,
        31.36491072025575,
        -16.796183786103345,
        3.0887360956199994,
        28.776253270099716,
        -18.312380543042117,
        3.0887360956199994,
        31.36491072025575,
        -16.796183786103345,
        4.7871256083020484,
        28.776253270099716,
        -18.312380543042117,
        4.7871256083020484,
        31.36491072025575,
        -16.796183786103345,
        5.6514204048341625,
        28.776253270099716,
        -18.312380543042117,
        5.6514204048341625,
        31.36491072025575,
        -16.796183786103345,
        6.5151602555535657,
        28.776253270099716,
        -18.312380543042117,
        6.5151602555535657,
        31.36491072025575,
        -16.796183786103345,
        7.6994042271669683,
        28.776253270099716,
        -18.312380543042117,
        7.6994042271669683,
        31.36491072025575,
        -16.796183786103345,
        9.5084709801467753,
        28.776253270099716,
        -18.312380543042117,
        9.5084709801467753,
        31.36491072025575,
        -16.796183786103345,
        11.017814467238885,
        28.776253270099716,
        -18.312380543042117,
        11.017814467238885,
        31.36491072025575,
        -16.796183786103345,
        12.197655497271676,
        28.776253270099716,
        -18.312380543042117,
        12.197655497271676,
        31.36491072025575,
        -16.796183786103345,
        13.547013315436761,
        28.776253270099716,
        -18.312380543042117,
        13.547013315436761,
        31.36491072025575,
        -16.796183786103345,
        14.705779802420896,
        28.776253270099716,
        -18.312380543042117,
        14.705779802420896,
        31.36491072025575,
        -16.796183786103345,
        16.719283108456413,
        28.776253270099716,
        -18.312380543042117,
        16.719283108456413,
        31.36491072025575,
        -16.796183786103345,
        19.167381233979842,
        28.776253270099716,
        -18.312380543042117,
        19.167381233979842,
        31.36491072025575,
        -16.796183786103345,
        21.916050758392416,
        28.776253270099716,
        -18.312380543042117,
        21.916050758392416,
        31.36491072025575,
        -16.796183786103345,
        24.443184336681703,
        28.776253270099716,
        -18.312380543042117,
        24.443184336681703,
        31.36491072025575,
        -16.796183786103345,
        26.740455903455956,
        28.776253270099716,
        -18.312380543042117,
        26.740455903455956,
        31.36491072025575,
        -16.796183786103345,
        29.353789019500788,
        28.776253270099716,
        -18.312380543042117,
        29.353789019500788,
        31.36491072025575,
        -16.796183786103345,
        31.168326721802522,
        28.776253270099716,
        -18.312380543042117,
        31.168326721802522,
        31.36491072025575
      ],
      "o": "10110"
    },
    {
      "c": [
        -24.348972506557985,
        0.53649174363665719,
        22.749777145256449,
        -26.547723467218198,
        0.53649174363665719,
        24.790731386045739,
        -24.348972506557985,
        2.20211429330009,
        22.749777145256449,
        -26.547723467218198,
        2.20211429330009,
        24.790731386045739,
        -24.348972506557985,
        3.4223686699012497,
        22.749777145256449,
        -26.547723467218198,
        3.4223686699012497,
        24.790731386045739,
        -24.348972506557985,
        3.9404502787168179,
        22.749777145256449,
        -26.547723467218198,
        3.9404502787168179,
        24.790731386045739,
        -24.348972506557985,
        4.8033222440200758,
        22.749777145256449,
        -26.547723467218198,
        4.8033222440200758,
        24.790731386045739,
        -24.348972506557985,
        6.8149463472674228,
        22.749777145256449,
        -26.547723467218198,
        6.8149463472674228,
        24.790731386045739,
        -24.348972506557985,
        9.3280541146480189,
        22.749777145256449,
        -26.547723467218198,
        9.3280541146480189,
        24.790731386045739,
        -24.348972506557985,
        12.035105770835674,
        22.749777145256449,
        -26.547723467218198,
        12.035105770835674,
        24.790731386045739,
        -24.348972506557985,
        15.119296197244584,
        22.749777145256449,
        -26.547723467218198,
        15.119296197244584,
        24.790731386045739,
        -24.348972506557985,
        18.102489553468459,
        22.749777145256449,
        -26.547723467218198,
        18.102489553468459,
        24.790731386045739,
        -24.348972506557985,
        20.122729591141049,
        22.749777145256449,
        -26.547723467218198,
        20.122729591141049,
        24.790731386045739,
        -24.348972506557985,
        22.782496846442623,
        22.749777145256449,
        -26.547723467218198,
        22.782496846442623,
        24.790731386045739,
        -24.348972506557985,
        26.11447640065051,
        22.749777145256449,
        -26.547723467218198,
        26.11447640065051,
        24.790731386045739,
        -24.348972506557985,
        28.879367321732026,
        22.749777145256449,
        -26.547723467218198,
        28.879367321732026,
        24.790731386045739,
        -24.348972506557985,
        31.306146111999251,
        22.749777145256449,
        -26.547723467218198,
        31.306146111999251,
        24.790731386045739,
        -24.348972506557985,
        33.0105159264847,
        22.749777145256449,
        -26.547723467218198,
        33.0105159264847,
        24.790731386045739,
        -24.348972506557985,
        35.041976713054972,
        22.749777145256449,
        -26.547723467218198,
        35.041976713054972,
        24.790731386045739,
        -24.348972506557985,
        36.89926790656186,
        22.749777145256449,
        -26.547723467218198,
        36.89926790656186,
        24.790731386045739
      ],
      "o": "10330"
    },
    {
      "c": [
        -28.842571096945015,
        0.75076901386831907,
        32.032387809111214,
        -30.854734231179819,
        0.75076901386831907,
        34.2575169112593,
        -28.842571096945015,
        2.7141855490949607,
        32.032387809111214,
        -30.854734231179819,
        2.7141855490949607,
        34.2575169112593,
        -28.842571096945015,
        4.0264231802085968,
        32.032387809111214,
        -30.854734231179819,
        4.0264231802085968,
        34.2575169112593,
        -28.842571096945015,
        4.4949362451968682,
        32.032387809111214,
        -30.854734231179819,
        4.4949362451968682,
        34.2575169112593,
        -28.842571096945015,
        5.6109994202480644,
        32.032387809111214,
        -30.854734231179819,
        5.6109994202480644,
        34.2575169112593,
        -28.842571096945015,
        6.6298078407679721,
        32.032387809111214,
        -30.854734231179819,
        6.6298078407679721,
        34.2575169112593,
        -28.842571096945015,
        9.8749847042465273,
        32.032387809111214,
        -30.854734231179819,
        9.8749847042465273,
        34.2575169112593,
        -28.842571096945015,
        13.205200940672936,
        32.032387809111214,
        -30.854734231179819,
        13.205200940672936,
        34.2575169112593,
        -28.842571096945015,
        14.445559174700776,
        32.032387809111214,
        -30.854734231179819,
        14.445559174700776,
        34.2575169112593,
        -28.842571096945015,
        15.947238787495564,
        32.032387809111214,
        -30.854734231179819,
        15.947238787495564,
        34.2575169112593,
        -28.842571096945015,
        16.942218314384988,
        32.032387809111214,
        -30.854734231179819,
        16.942218314384988,
        34.2575169112593,
        -28.842571096945015,
        17.724303498324478,
        32.032387809111214,
        -30.854734231179819,
        17.724303498324478,
        34.2575169112593,
        -28.842571096945015,
        18.86528949392239,
        32.032387809111214,
        -30.854734231179819,
        18.86528949392239,
        34.2575169112593,
        -28.842571096945015,
        20.98321088819845,
        32.032387809111214,
        -30.854734231179819,
        20.98321088819845,
        34.2575169112593,
        -28.842571096945015,
        22.9678924473667,
        32.032387809111214,
        -30.854734231179819,
        22.9678924473667,
        34.2575169112593,
        -28.842571096945015,
        24.251314200991452,
        32.032387809111214,
        -30.854734231179819,
        24.251314200991452,
        34.2575169112593,
        -28.842571096945015,
        25.465510220882621,
        32.032387809111214,
        -30.854734231179819,
        25.465510220882621,
        34.2575169112593,
        -28.842571096945015,
        26.740751907946308,
        32.032387809111214,
        -30.854734231179819,
        26.740751907946308,
        34.2575169112593
      ],
      "o": "10120"
    },
    {
      "c": [
        -24.946368167966416,
        0.51320490666396734,
        26.40636418886654,
        -27.012344448027051,
        0.51320490666396734,
        28.581620957670356,
        -24.946368167966416,
        1.5996432238915324,
        26.40636418886654,
        -27.012344448027051,
        1.5996432238915324,
        28.581620957670356,
        -24.946368167966416,
        2.2759659110825892,
        26.40636418886654,
        -27.012344448027051,
        2.2759659110825892,
        28.581620957670356,
        -24.946368167966416,
        2.6349859572063039,
        26.40636418886654,
        -27.012344448027051,
        2.6349859572063039,
        28.581620957670356,
        -24.946368167966416,
        3.8193118027351591,
        26.40636418886654,
        -27.012344448027051,
        3.8193118027351591,
        28.581620957670356,
        -24.946368167966416,
        5.1791720361428464,
        26.40636418886654,
        -27.012344448027051,
        5.1791720361428464,
        28.581620957670356,
        -24.946368167966416,
        7.6093941637550877,
        26.40636418886654,
        -27.012344448027051,
        7.6093941637550877,
        28.581620957670356,
        -24.946368167966416,
        10.106887256022585,
        26.40636418886654,
        -27.012344448027051,
        10.106887256022585,
        28.581620957670356,
        -24.946368167966416,
        11.399833868182341,
        26.40636418886654,
        -27.012344448027051,
        11.399833868182341,
        28.581620957670356,
        -24.946368167966416,
        12.831571776991497,
        26.40636418886654,
        -27.012344448027051,
        12.831571776991497,
        28.581620957670356,
        -24.946368167966416,
        13.826560694886327,
        26.40636418886654,
        -27.012344448027051,
        13.826560694886327,
        28.581620957670356,
        -24.946368167966416,
        14.765957740694027,
        26.40636418886654,
        -27.012344448027051,
        14.765957740694027,
        28.581620957670356,
        -24.946368167966416,
        15.981081341492363,
        26.40636418886654,
        -27.012344448027051,
        15.981081341492363,
        28.581620957670356,
        -24.946368167966416,
        17.640415525245935,
        26.40636418886654,
        -27.012344448027051,
        17.640415525245935,
        28.581620957670356,
        -24.946368167966416,
        19.274813208888244,
        26.40636418886654,
        -27.012344448027051,
        19.274813208888244,
        28.581620957670356,
        -24.946368167966416,
        20.501309192018191,
        26.40636418886654,
        -27.012344448027051,
        20.501309192018191,
        28.581620957670356,
        -24.946368167966416,
        21.91870590865382,
        26.40636418886654,
        -27.012344448027051,
        21.91870590865382,
        28.581620957670356,
        -24.946368167966416,
        23.648879826936508,
        26.40636418886654,
        -27.012344448027051,
        23.648879826936508,
        28.581620957670356
      ],
      "o": "10500"
    },
    {
      "c": [
        -27.312974153710133,
        1.2961267665783052,
        22.625047063606111,
        -29.62971512150812,
        1.2961267665783052,
        24.531014347723669,
        -27.312974153710133,
        4.2332500102182733,
        22.625047063606111,
        -29.62971512150812,
        4.2332500102182733,
        24.531014347723669,
        -27.312974153710133,
        5.9796655940899086,
        22.625047063606111,
        -29.62971512150812,
        5.9796655940899086,
        24.531014347723669,
        -27.312974153710133,
        6.6270062534289575,
        22.625047063606111,
        -29.62971512150812,
        6.6270062534289575,
        24.531014347723669,
        -27.312974153710133,
        7.6624118047440479,
        22.625047063606111,
        -29.62971512150812,
        7.6624118047440479,
        24.531014347723669,
        -27.312974153710133,
        9.7950496557696063,
        22.625047063606111,
        -29.62971512150812,
        9.7950496557696063,
        24.531014347723669,
        -27.312974153710133,
        13.431576948375067,
        22.625047063606111,
        -29.62971512150812,
        13.431576948375067,
        24.531014347723669,
        -27.312974153710133,
        17.250711464579865,
        22.625047063606111,
        -29.62971512150812,
        17.250711464579865,
        24.531014347723669,
        -27.312974153710133,
        21.0711025599145,
        22.625047063606111,
        -29.62971512150812,
        21.0711025599145,
        24.531014347723669,
        -27.312974153710133,
        24.871110540625079,
        22.625047063606111,
        -29.62971512150812,
        24.871110540625079,
        24.531014347723669,
        -27.312974153710133,
        27.105758500892019,
        22.625047063606111,
        -29.62971512150812,
        27.105758500892019,
        24.531014347723669,
        -27.312974153710133,
        29.689550644268998,
        22.625047063606111,
        -29.62971512150812,
        29.689550644268998,
        24.531014347723669,
        -27.312974153710133,
        33.076607819108048,
        22.625047063606111,
        -29.62971512150812,
        33.076607819108048,
        24.531014347723669,
        -27.312974153710133,
        36.905047006074469,
        22.625047063606111,
        -29.62971512150812,
        36.905047006074469,
        24.531014347723669,
        -27.312974153710133,
        40.280930887896488,
        22.625047063606111,
        -29.62971512150812,
        40.280930887896488,
        24.531014347723669,
        -27.312974153710133,
        42.204942545321813,
        22.625047063606111,
        -29.62971512150812,
        42.204942545321813,
        24.531014347723669,
        -27.312974153710133,
        43.8828833159037,
        22.625047063606111,
        -29.62971512150812,
        43.8828833159037,
        24.531014347723669,
        -27.312974153710133,
        45.361229911456718,
        22.625047063606111,
        -29.62971512150812,
        45.361229911456718,
        24.531014347723669
      ],
      "o": "10100"
    },
    {
      "c": [
        -29.732099650212795,
        1.8618749745482448,
        20.96415509987602,
        -32.190217015521668,
        1.8618749745482448,
        22.683939684749063,
        -29.732099650212795,
        5.23127558796907,
        20.96415509987602,
        -32.190217015521668,
        5.23127558796907,
        22.683939684749063,
        -29.732099650212795,
        6.9134167048416391,
        20.96415509987602,
        -32.190217015521668,
        6.9134167048416391,
        22.683939684749063,
        -29.732099650212795,
        7.5064360325181045,
        20.96415509987602,
        -32.190217015521668,
        7.5064360325181045,
        22.683939684749063,
        -29.732099650212795,
        9.0453018354806609,
        20.96415509987602,
        -32.190217015521668,
        9.0453018354806609,
        22.683939684749063,
        -29.732099650212795,
        11.449497511591034,
        20.96415509987602,
        -32.190217015521668,
        11.449497511591034,
        22.683939684749063,
        -29.732099650212795,
        15.580104693578786,
        20.96415509987602,
        -32.190217015521668,
        15.580104693578786,
        22.683939684749063,
        -29.732099650212795,
        19.498632671885719,
        20.96415509987602,
        -32.190217015521668,
        19.498632671885719,
        22.683939684749063,
        -29.732099650212795,
        22.215073517883631,
        20.96415509987602,
        -32.190217015521668,
        22.215073517883631,
        22.683939684749063,
        -29.732099650212795,
        24.988936981168955,
        20.96415509987602,
        -32.190217015521668,
        24.988936981168955,
        22.683939684749063,
        -29.732099650212795,
        27.102862314825231,
        20.96415509987602,
        -32.190217015521668,
        27.102862314825231,
        22.683939684749063,
        -29.732099650212795,
        29.401126573477455,
        20.96415509987602,
        -32.190217015521668,
        29.401126573477455,
        22.683939684749063,
        -29.732099650212795,
        32.203939250876729,
        20.96415509987602,
        -32.190217015521668,
        32.203939250876729,
        22.683939684749063,
        -29.732099650212795,
        36.219522393546761,
        20.96415509987602,
        -32.190217015521668,
        36.219522393546761,
        22.683939684749063,
        -29.732099650212795,
        39.955110581449631,
        20.96415509987602,
        -32.190217015521668,
        39.955110581449631,
        22.683939684749063,
        -29.732099650212795,
        42.164010283098136,
        20.96415509987602,
        -32.190217015521668,
        42.164010283098136,
        22.683939684749063,
        -29.732099650212795,
        43.795732281480554,
        20.96415509987602,
        -32.190217015521668,
        43.795732281480554,
        22.683939684749063,
        -29.732099650212795,
        45.544127880583389,
        20.96415509987602,
        -32.190217015521668,
        45.544127880583389,
        22.683939684749063
      ],
      "o": "10200"
    },
    {
      "c": [
        -30.838267428959071,
        1.2015991756681994,
        31.146142351332895,
        -32.953939168351454,
        1.2015991756681994,
        33.273096297975,
        -30.838267428959071,
        3.6533903297186772,
        31.146142351332895,
        -32.953939168351454,
        3.6533903297186772,
        33.273096297975,
        -30.838267428959071,
        5.0116465480999,
        31.146142351332895,
        -32.953939168351454,
        5.0116465480999,
        33.273096297975,
        -30.838267428959071,
        5.5527264161179115,
        31.146142351332895,
        -32.953939168351454,
        5.5527264161179115,
        33.273096297975,
        -30.838267428959071,
        6.6378521759975841,
        31.146142351332895,
        -32.953939168351454,
        6.6378521759975841,
        33.273096297975,
        -30.838267428959071,
        7.6759001058067291,
        31.146142351332895,
        -32.953939168351454,
        7.6759001058067291,
        33.273096297975,
        -30.838267428959071,
        10.884168752877493,
        31.146142351332895,
        -32.953939168351454,
        10.884168752877493,
        33.273096297975,
        -30.838267428959071,
        14.182899139416747,
        31.146142351332895,
        -32.953939168351454,
        14.182899139416747,
        33.273096297975,
        -30.838267428959071,
        15.737871080108581,
        31.146142351332895,
        -32.953939168351454,
        15.737871080108581,
        33.273096297975,
        -30.838267428959071,
        17.526619954648538,
        31.146142351332895,
        -32.953939168351454,
        17.526619954648538,
        33.273096297975,
        -30.838267428959071,
        18.636711921519897,
        31.146142351332895,
        -32.953939168351454,
        18.636711921519897,
        33.273096297975,
        -30.838267428959071,
        19.483950648822706,
        31.146142351332895,
        -32.953939168351454,
        19.483950648822706,
        33.273096297975,
        -30.838267428959071,
        20.801221467541332,
        31.146142351332895,
        -32.953939168351454,
        20.801221467541332,
        33.273096297975,
        -30.838267428959071,
        23.423019761071309,
        31.146142351332895,
        -32.953939168351454,
        23.423019761071309,
        33.273096297975,
        -30.838267428959071,
        25.789590937488327,
        31.146142351332895,
        -32.953939168351454,
        25.789590937488327,
        33.273096297975,
        -30.838267428959071,
        27.105920026184979,
        31.146142351332895,
        -32.953939168351454,
        27.105920026184979,
        33.273096297975,
        -30.838267428959071,
        28.34091336664526,
        31.146142351332895,
        -32.953939168351454,
        28.34091336664526,
        33.273096297975,
        -30.838267428959071,
        29.626317999757845,
        31.146142351332895,
        -32.953939168351454,
        29.626317999757845,
        33.273096297975
      ],
      "o": "10600"
    },
    {
      "c": [
        -34.767291295759847,
        1.4375616469007901,
        21.436819427500062,
        -37.326412619780456,
        1.4375616469007901,
        23.002353856678486,
        -34.767291295759847,
        4.27223606483022,
        21.436819427500062,
        -37.326412619780456,
        4.27223606483022,
        23.002353856678486,
        -34.767291295759847,
        5.7879316279780246,
        21.436819427500062,
        -37.326412619780456,
        5.7879316279780246,
        23.002353856678486,
        -34.767291295759847,
        6.3558146775549247,
        21.436819427500062,
        -37.326412619780456,
        6.3558146775549247,
        23.002353856678486,
        -34.767291295759847,
        7.5402820924902549,
        21.436819427500062,
        -37.326412619780456,
        7.5402820924902549,
        23.002353856678486,
        -34.767291295759847,
        9.3828117524217038,
        21.436819427500062,
        -37.326412619780456,
        9.3828117524217038,
        23.002353856678486,
        -34.767291295759847,
        13.390499125567617,
        21.436819427500062,
        -37.326412619780456,
        13.390499125567617,
        23.002353856678486,
        -34.767291295759847,
        17.64678963886098,
        21.436819427500062,
        -37.326412619780456,
        17.64678963886098,
        23.002353856678486,
        -34.767291295759847,
        20.678492796962832,
        21.436819427500062,
        -37.326412619780456,
        20.678492796962832,
        23.002353856678486,
        -34.767291295759847,
        23.8898343688221,
        21.436819427500062,
        -37.326412619780456,
        23.8898343688221,
        23.002353856678486,
        -34.767291295759847,
        25.831752169285959,
        21.436819427500062,
        -37.326412619780456,
        25.831752169285959,
        23.002353856678486,
        -34.767291295759847,
        26.955358168574811,
        21.436819427500062,
        -37.326412619780456,
        26.955358168574811,
        23.002353856678486,
        -34.767291295759847,
        28.54221845764544,
        21.436819427500062,
        -37.326412619780456,
        28.54221845764544,
        23.002353856678486,
        -34.767291295759847,
        32.152328038160526,
        21.436819427500062,
        -37.326412619780456,
        32.152328038160526,
        23.002353856678486,
        -34.767291295759847,
        35.563249135080227,
        21.436819427500062,
        -37.326412619780456,
        35.563249135080227,
        23.002353856678486,
        -34.767291295759847,
        37.048124858444169,
        21.436819427500062,
        -37.326412619780456,
        37.048124858444169,
        23.002353856678486,
        -34.767291295759847,
        38.420513616510505,
        21.436819427500062,
        -37.326412619780456,
        38.420513616510505,
        23.002353856678486,
        -34.767291295759847,
        39.883117130603196,
        21.436819427500062,
        -37.326412619780456,
        39.883117130603196,
        23.002353856678486
      ],
      "o": "10700"
    },
    {
      "c": [
        -48.832350346371527,
        1.4497867012197903,
        -27.096662809706263,
        -51.450054556166847,
        1.4497867012197903,
        -28.562140434926061,
        -48.832350346371527,
        3.5281892108889181,
        -27.096662809706263,
        -51.450054556166847,
        3.5281892108889181,
        -28.562140434926061,
        -48.832350346371527,
        4.2953469847941665,
        -27.096662809706263,
        -51.450054556166847,
        4.2953469847941665,
        -28.562140434926061,
        -48.832350346371527,
        4.7522269418374439,
        -27.096662809706263,
        -51.450054556166847,
        4.7522269418374439,
        -28.562140434926061,
        -48.832350346371527,
        5.4021813371040643,
        -27.096662809706263,
        -51.450054556166847,
        5.4021813371040643,
        -28.562140434926061,
        -48.832350346371527,
        6.5241724904068832,
        -27.096662809706263,
        -51.450054556166847,
        6.5241724904068832,
        -28.562140434926061,
        -48.832350346371527,
        8.35953812795535,
        -27.096662809706263,
        -51.450054556166847,
        8.35953812795535,
        -28.562140434926061,
        -48.832350346371527,
        10.522347839391845,
        -27.096662809706263,
        -51.450054556166847,
        10.522347839391845,
        -28.562140434926061,
        -48.832350346371527,
        13.765714868308818,
        -27.096662809706263,
        -51.450054556166847,
        13.765714868308818,
        -28.562140434926061,
        -48.832350346371527,
        17.151600796953808,
        -27.096662809706263,
        -51.450054556166847,
        17.151600796953808,
        -28.562140434926061,
        -48.832350346371527,
        18.766028570209862,
        -27.096662809706263,
        -51.450054556166847,
        18.766028570209862,
        -28.562140434926061,
        -48.832350346371527,
        19.950693008438584,
        -27.096662809706263,
        -51.450054556166847,
        19.950693008438584,
        -28.562140434926061,
        -48.832350346371527,
        21.505773509152888,
        -27.096662809706263,
        -51.450054556166847,
        21.505773509152888,
        -28.562140434926061,
        -48.832350346371527,
        22.960982295163159,
        -27.096662809706263,
        -51.450054556166847,
        22.960982295163159,
        -28.562140434926061,
        -48.832350346371527,
        24.316077704596964,
        -27.096662809706263,
        -51.450054556166847,
        24.316077704596964,
        -28.562140434926061,
        -48.832350346371527,
        25.686361249678392,
        -27.096662809706263,
        -51.450054556166847,
        25.686361249678392,
        -28.562140434926061,
        -48.832350346371527,
        26.965641319492889,
        -27.096662809706263,
        -51.450054556166847,
        26.965641319492889,
        -28.562140434926061,
        -48.832350346371527,
        28.179829576152919,
        -27.096662809706263,
        -51.450054556166847,
        28.179829576152919,
        -28.562140434926061
      ],
      "o": "11120"
    },
    {
      "c": [
        8.21237851816022,
        2.6208691787585936,
        -14.784401110250666,
        9.6621729230878479,
        2.6208691787585936,
        -17.410823807274234,
        8.21237851816022,
        6.0236823633420915,
        -14.784401110250666,
        9.6621729230878479,
        6.0236823633420915,
        -17.410823807274234,
        8.21237851816022,
        6.9012747609204705,
        -14.784401110250666,
        9.6621729230878479,
        6.9012747609204705,
        -17.410823807274234,
        8.21237851816022,
        7.4001664762586881,
        -14.784401110250666,
        9.6621729230878479,
        7.4001664762586881,
        -17.410823807274234,
        8.21237851816022,
        8.59324794741818,
        -14.784401110250666,
        9.6621729230878479,
        8.59324794741818,
        -17.410823807274234,
        8.21237851816022,
        10.108907073465014,
        -14.784401110250666,
        9.6621729230878479,
        10.108907073465014,
        -17.410823807274234,
        8.21237851816022,
        11.829316474754776,
        -14.784401110250666,
        9.6621729230878479,
        11.829316474754776,
        -17.410823807274234,
        8.21237851816022,
        13.560006272267698,
        -14.784401110250666,
        9.6621729230878479,
        13.560006272267698,
        -17.410823807274234,
        8.21237851816022,
        17.170553473634637,
        -14.784401110250666,
        9.6621729230878479,
        17.170553473634637,
        -17.410823807274234,
        8.21237851816022,
        20.8536115076391,
        -14.784401110250666,
        9.6621729230878479,
        20.8536115076391,
        -17.410823807274234,
        8.21237851816022,
        22.029430495270489,
        -14.784401110250666,
        9.6621729230878479,
        22.029430495270489,
        -17.410823807274234,
        8.21237851816022,
        23.078808109640008,
        -14.784401110250666,
        9.6621729230878479,
        23.078808109640008,
        -17.410823807274234,
        8.21237851816022,
        24.8727356854137,
        -14.784401110250666,
        9.6621729230878479,
        24.8727356854137,
        -17.410823807274234,
        8.21237851816022,
        26.924467395396796,
        -14.784401110250666,
        9.6621729230878479,
        26.924467395396796,
        -17.410823807274234,
        8.21237851816022,
        28.51835603812566,
        -14.784401110250666,
        9.6621729230878479,
        28.51835603812566,
        -17.410823807274234,
        8.21237851816022,
        29.77103226612266,
        -14.784401110250666,
        9.6621729230878479,
        29.77103226612266,
        -17.410823807274234,
        8.21237851816022,
        31.072260993768872,
        -14.784401110250666,
        9.6621729230878479,
        31.072260993768872,
        -17.410823807274234,
        8.21237851816022,
        32.402294814899427,
        -14.784401110250666,
        9.6621729230878479,
        32.402294814899427,
        -17.410823807274234
      ],
      "o": "10220"
    },
    {
      "c": [
        12.223464120521855,
        2.5868336709040807,
        -32.752226776551765,
        13.271340660755698,
        2.5868336709040807,
        -35.563268355117891,
        12.223464120521855,
        5.9606521263007615,
        -32.752226776551765,
        13.271340660755698,
        5.9606521263007615,
        -35.563268355117891,
        12.223464120521855,
        6.8436108574008632,
        -32.752226776551765,
        13.271340660755698,
        6.8436108574008632,
        -35.563268355117891,
        12.223464120521855,
        7.3452413029485868,
        -32.752226776551765,
        13.271340660755698,
        7.3452413029485868,
        -35.563268355117891,
        12.223464120521855,
        8.561582729327796,
        -32.752226776551765,
        13.271340660755698,
        8.561582729327796,
        -35.563268355117891,
        12.223464120521855,
        10.103726994883816,
        -32.752226776551765,
        13.271340660755698,
        10.103726994883816,
        -35.563268355117891,
        12.223464120521855,
        11.860614191500325,
        -32.752226776551765,
        13.271340660755698,
        11.860614191500325,
        -35.563268355117891,
        12.223464120521855,
        13.625988728374733,
        -32.752226776551765,
        13.271340660755698,
        13.625988728374733,
        -35.563268355117891,
        12.223464120521855,
        17.205888070570126,
        -32.752226776551765,
        13.271340660755698,
        17.205888070570126,
        -35.563268355117891,
        12.223464120521855,
        20.857106839850708,
        -32.752226776551765,
        13.271340660755698,
        20.857106839850708,
        -35.563268355117891,
        12.223464120521855,
        22.035709947551062,
        -32.752226776551765,
        13.271340660755698,
        22.035709947551062,
        -35.563268355117891,
        12.223464120521855,
        23.099451417720481,
        -32.752226776551765,
        13.271340660755698,
        23.099451417720481,
        -35.563268355117891,
        12.223464120521855,
        24.917681037280296,
        -32.752226776551765,
        13.271340660755698,
        24.917681037280296,
        -35.563268355117891,
        12.223464120521855,
        26.993437443356562,
        -32.752226776551765,
        13.271340660755698,
        26.993437443356562,
        -35.563268355117891,
        12.223464120521855,
        28.601226026218342,
        -32.752226776551765,
        13.271340660755698,
        28.601226026218342,
        -35.563268355117891,
        12.223464120521855,
        29.857165315559936,
        -32.752226776551765,
        13.271340660755698,
        29.857165315559936,
        -35.563268355117891,
        12.223464120521855,
        31.170309350863555,
        -32.752226776551765,
        13.271340660755698,
        31.170309350863555,
        -35.563268355117891,
        12.223464120521855,
        32.510386539240855,
        -32.752226776551765,
        13.271340660755698,
        32.510386539240855,
        -35.563268355117891
      ],
      "o": "12130"
    },
    {
      "c": [
        20.806925701207859,
        2.5749902044380222,
        -70.319228474149156,
        21.658178655654254,
        2.5749902044380222,
        -73.195922480738361,
        20.806925701207859,
        5.93871896362796,
        -70.319228474149156,
        21.658178655654254,
        5.93871896362796,
        -73.195922480738361,
        20.806925701207859,
        6.8235450961527739,
        -70.319228474149156,
        21.658178655654254,
        6.8235450961527739,
        -73.195922480738361,
        20.806925701207859,
        7.3261287287490688,
        -70.319228474149156,
        21.658178655654254,
        7.3261287287490688,
        -73.195922480738361,
        20.806925701207859,
        8.55056363661951,
        -70.319228474149156,
        21.658178655654254,
        8.55056363661951,
        -73.195922480738361,
        20.806925701207859,
        10.101924547913153,
        -70.319228474149156,
        21.658178655654254,
        10.101924547913153,
        -73.195922480738361,
        20.806925701207859,
        11.871504940468082,
        -70.319228474149156,
        21.658178655654254,
        11.871504940468082,
        -73.195922480738361,
        20.806925701207859,
        13.648949110522043,
        -70.319228474149156,
        21.658178655654254,
        13.648949110522043,
        -73.195922480738361,
        20.806925701207859,
        17.21818402697993,
        -70.319228474149156,
        21.658178655654254,
        17.21818402697993,
        -73.195922480738361,
        20.806925701207859,
        20.858323288084122,
        -70.319228474149156,
        21.658178655654254,
        20.858323288084122,
        -73.195922480738361,
        20.806925701207859,
        22.037894295408179,
        -70.319228474149156,
        21.658178655654254,
        22.037894295408179,
        -73.195922480738361,
        20.806925701207859,
        23.106634910788003,
        -70.319228474149156,
        21.658178655654254,
        23.106634910788003,
        -73.195922480738361,
        20.806925701207859,
        24.933321443748206,
        -70.319228474149156,
        21.658178655654254,
        24.933321443748206,
        -73.195922480738361,
        20.806925701207859,
        27.017437722835677,
        -70.319228474149156,
        21.658178655654254,
        27.017437722835677,
        -73.195922480738361,
        20.806925701207859,
        28.630061421346795,
        -70.319228474149156,
        21.658178655654254,
        28.630061421346795,
        -73.195922480738361,
        20.806925701207859,
        29.887137648409375,
        -70.319228474149156,
        21.658178655654254,
        29.887137648409375,
        -73.195922480738361,
        20.806925701207859,
        31.20442875169968,
        -70.319228474149156,
        21.658178655654254,
        31.20442875169968,
        -73.195922480738361,
        20.806925701207859,
        32.547999394087512,
        -70.319228474149156,
        21.658178655654254,
        32.547999394087512,
        -73.195922480738361
      ],
      "o": "12120"
    }
  ]
}