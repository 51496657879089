import React, { Suspense, useRef, useEffect, useState, useCallback } from "react"
import tw, { styled, css } from "twin.macro"
import Layout from "../components/BasicLayout"
import SEO from "../components/seo"
import { Scene } from "../components/map/SceneSetup"
import Model from "../components/map/Base_one"
import Navigation from "../components/Navigation"
import Controls from "../components/map/Controls"
import { up, down } from "../utils/screens"
import { useMediaQuery } from "../utils/useMediaQuery"
import { isClient } from "../utils/common"
import { Helmet } from "react-helmet"
import { FilterList, ExpandLess, ExpandMore } from "@styled-icons/material-rounded"
import {UnlockFill, LockFill} from '@styled-icons/bootstrap'
import { Wiggle } from "../components/Wiggle"
import {useSnapshot } from "valtio"
import state,{stateActions} from '../components/Store'
import {ENames, CNames, ElementsData} from '../data/elements'
import {Slider} from '../components/CommonInputs'
import {Curves} from '../components/map/Curves'
import {People} from '../components/map/People'
import {cssNavBaseText,cssNavButton, isButtonActive} from '../utils/cssVars'
const MaterialDetails=({material})=>{
  const [isOpen, setisOpen] = useState(false)
  return (
    <>
      <div
        tw={"block p-2 w-full mb-3 text-black"}
        css={css`
          background-color: rgba(255, 255, 255, 0.7);
        `}
      >
        <div
          tw={"pointer-events-auto relative p-5 cursor-auto filter drop-shadow-lg "}
        >
          {isOpen? (
          <button tw="absolute top-0 right-0 block w-10 h-10 mr-5 mt-5" onClick={()=>setisOpen((p)=>!p)}>
            <ExpandMore/>
          </button>
          )
          :(
            <button tw="absolute top-0 right-0 block w-10 h-10 mr-5 mt-5" onClick={()=>setisOpen((p)=>!p)}>
            <ExpandLess/>
          </button>
          )}
          <h2 tw="text-2xl md:text-4xl font-light">{ElementsData[material]?.symbol}</h2>
          <h1 tw="text-lg md:text-2xl font-black">{ElementsData[material]?.name}</h1>
          {isOpen && (
            <>
          <br />
          <div tw={"w-full h-full max-height[35vh] overflow-y-auto"}>
          <p tw="pr-2 text-sm md:text-base">{ElementsData[material]?.description}</p>
          </div>
          </>
          )}
        </div>
      </div>
    </>
  )
}
const Filteration =({active, clicked})=>{
  const snap = useSnapshot(state)
  // slider material
  const [materialLock, setmaterialLock] = useState(true)
  const [mSlider, setmSlider] = useState(0);
  const onmSliderChange = useCallback (value => {stateActions.updateMaterial(ENames[value]);},[])
  useEffect(() => {
    let material = snap.isolateMaterial
    let match = ENames[mSlider] == material
    if(!match && material){
      setmaterialLock((p)=>false)
      setmSlider((p)=>ENames.indexOf(material))
    }
    else if(!material){
      setmaterialLock((p)=>true)
    }
    else{
      if(materialLock)
      setmaterialLock((p)=>false)
    }
  }, [snap.isolateMaterial])
  // slider District
  const [districtLock, setdistrictLock] = useState(true)
  const [dSlider, setdSlider] = useState(0);
  const ondSliderChange = useCallback (value => {stateActions.updateCity(CNames[value]);},[])
  useEffect(() => {
    let district = snap.isolateCity
    let match = CNames[dSlider] == district
    if(!match && district){
      setdistrictLock((p)=>false)
      setdSlider((p)=>CNames.indexOf(district))
    }
    else if(!district){
      setdistrictLock((p)=>true)
    }
    else{
      if(districtLock)
      setdistrictLock((p)=>false)
    }
  }, [snap.isolateCity])
  return(
    <div tw={"pointer-events-none w-full absolute bottom-0 left-0 p-6 object-right-bottom flex flex-col justify-start align-items[flex-start] text-gray-50 text-xl z-50"}>
    {active && !materialLock && <MaterialDetails material={snap.isolateMaterial}/>}
    {active? 
    (
      <>
      <div tw={"block p-2 w-full text-black"}
              css={css`
              background-color: rgba(255, 255, 255, 0.7);
            `}>
        <div tw={"pointer-events-auto grid grid-cols-5 gap-1"}>
          {/* first row */}
          <div tw={"p-1 flex justify-center items-center text-xs"}>
            {materialLock?
            (
            <>
            <button
              tw={"w-5 h-5 flex flex-col justify-center items-center"}
              onClick={() => (setmaterialLock((prev)=> true), stateActions.selectedMaterial(ENames[mSlider]))}
              title={"Enable Material isolation"}
            > 
            <LockFill />
            </button>
            </>)
          :(
          <button
            tw={"w-5 h-5 flex flex-col justify-center items-center"}
            onClick={() => {setmaterialLock((prev)=> false); stateActions.deselectMaterial()}}
            title={"Disable Material isolation"}
            >
            <UnlockFill />
          </button>
          )}
          </div>
          <div tw={"col-span-3 flex justify-center items-center"}>
          <Slider disabled={materialLock} tw={"w-full"} label="Isolate Material" min={0} max={17} step={1} value={mSlider} displayLabel={false} displayNum={false} onChange={onmSliderChange}/>
          </div>
          <div tw={"content-center flex flex-col justify-center items-center text-xs"}>{!materialLock? ENames[mSlider] : "Isolate Material"}</div>
{/* second row */}
<div tw={"p-1 flex justify-center items-center"}>
            {districtLock?
            (<button
              tw={"w-5 h-5 flex flex-col justify-center items-center"}
              title={"Enable District isolation"}
              onClick={() => (setdistrictLock((prev)=> true), stateActions.selectedCity(CNames[dSlider]))}
            >
              <LockFill />
            </button>)
          :(<button
            tw={"w-5 h-5 flex flex-col justify-center items-center"}
            title={"Disable District isolation"}
            onClick={() => {setdistrictLock((prev)=> false); stateActions.deselectCity()}}
          >
            <UnlockFill />
          </button>)
          }
          </div>
          <div tw={"col-span-3 flex justify-center items-center"}>
          <Slider disabled={districtLock} tw={"w-full"} label="Isolate District" min={0} max={25} step={1} value={dSlider} displayLabel={false} displayNum={false} onChange={ondSliderChange}/>
          </div>
          <div tw={"content-center flex flex-col justify-center items-center text-xs"}>{!districtLock? CNames[dSlider] : "Isolate District"}</div>
        </div>
      </div>
    <button tw={"pointer-events-auto flex items-center justify-center"} title={"Close Filter"} onClick={clicked}
    css={[cssNavBaseText, cssNavButton, css`
          background-color: rgba(255, 255, 255, 0.7);
        `]}>
      <FilterList tw={"m-2 h-7 w-7"}/>
      </button>
    </>
    )
    :(
      <Wiggle rotation={20} timing={200} customStyle={tw`flex align-content[center] justify-center`}>
      <button tw={"pointer-events-auto m-2 w-7 h-7"} title={"Filter"} onClick={clicked}>
        <FilterList/>
        </button>
    </Wiggle>
    )}
    </div>
  )
}
const ToxiCartography = props => {
  const isUpMd = useMediaQuery(up("xl"))
  const [filter,setFilter] = useState(false)
  const changeFilter = useCallback(
    () => {
      setFilter(prev => !prev)
    },
    [],
  )
  return (
    <Layout>
      <SEO title="Toxi-cartography" /> 
      <Helmet>
        <style type="text/css">{`
      body {
        overflow: hidden !important;
      }
      html {
        overflow: hidden !important;
      }
  `}</style>
      </Helmet>
      <Navigation location={props.location}/>
      <div
        tw="h-full w-full z-10"
        css={css`
          position: absolute;
        `}
      >
        {isClient && (
          <>
            {isUpMd && <Controls />}
            <Scene>
              <Model />
              <Curves/>
              <People/>
            </Scene>
          </>
        )}
      </div>

      {!isUpMd && <Filteration active={filter} clicked={changeFilter}/>}

    </Layout>
  )
}
export default ToxiCartography
