
import * as THREE from "three"
import React, { Suspense, useMemo} from "react"

export const ShadowPlane = ({width,height,opacity, position})=>{
    const [
        planeGeometry,
      ] = React.useMemo(() => {
        const planeGeometry = new THREE.PlaneBufferGeometry(width, height)
        return [
          planeGeometry,
        ]
      }, [width, height])

    return(
        <mesh geometry={planeGeometry}  position={position} receiveShadow scale={[1, -1, 1]} rotation={[-Math.PI / 2, 0, 0]}>
        <shadowMaterial transparent={true} opacity={opacity} />
      </mesh>
    )
}